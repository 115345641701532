.topic-teaser-big-c {
  @include topic-teaser-big-prototype;

  background-color: $palette-grape;
  color: $palette-orange;

  @include media("<md") {
    padding-top: 13.25space;
    padding-bottom: 13.25space;
  }

  @include media(">=md", "<lg") {
    padding-top: 18space;
    padding-bottom: 18space;
  }

  @include media(">=lg") {
    padding-top: 22space;
    padding-bottom: 22space;
  }

  > .background {
    grid-column: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    mix-blend-mode: multiply;
    opacity: 0.25;
    overflow: hidden;

    > .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &[data-in-viewport].-in-viewport > .background > .image {
    animation: blur-in-gray $base-transition-duration-long $base-transition-easing forwards;
  }

  > .content {
    @include fade-up-animation;

    @include media(">=md") {
      grid-column: grid;
    }

    @include media(">=lg") {
      display: grid;
      grid-template-columns: 4fr 6fr;
      grid-gap: 14space;
      align-items: center;
    }

    > .box {
      @include media(">=lg") {
        &:first-of-type {
          order: 1;
        }
      }

      & + .box {
        @include media("<md") {
          margin-top: 3.75space;
        }

        @include media(">=md", "<lg") {
          margin-top: 9.125space;
        }
      }

      > .title {
        margin-top: 0;
        margin-bottom: 0;

        & + .caption {
          @include media("<md") {
            margin-top: 1.875space;
          }

          @include media(">=md", "<lg") {
            margin-top: 4.75space;
          }

          @include media(">=lg") {
            margin-top: 4space;
          }
        }
      }

      > .caption {
        @extend %body-text-extra-large;

        margin-bottom: 0;

        @include media("<md") {
          padding-left: 1.75space;
        }

        @include media(">=md", "<lg") {
          padding-left: 7.125space;
        }

        @include media(">=lg") {
          padding-left: 7.625space;
        }
      }

      > .links {
        @include media("<md") {
          padding-left: 1.75space;
        }

        @include media(">=md", "<lg") {
          padding-left: 7.125space;
        }
      }
    }
  }
}
