.video-gallery {
    position: relative;

    @include media(">=lg") {
        margin-bottom: calc(20px + 2.5rem); // due to flickity absolute controls
    }

    > .video {
        margin-left: var(--grid-outer-margin);
        margin-right: var(--grid-outer-margin);
    }

    > .text {
        margin-left: var(--grid-outer-margin);
        margin-right: var(--grid-outer-margin);

        @include media("<md") {
            margin-top: 1rem;
        }

        @include media(">=md") {
            margin-top: 2rem;
        }

        > .title {
            margin-bottom: 0.5rem;
        }

        > .lead {
        }
    }

    > .videos {
        margin-left: var(--grid-outer-margin);

        @include media("<md") {
            margin-top: 2rem;
        }

        @include media(">=md", "<lg") {
            margin-top: 3rem;
        }

        @include media(">=lg") {
            margin-top: 4rem;
        }

        > .container {
            width: 100%;
            max-width: 100%;
        }
    }

    .flickity-button {
        display: flex;
        align-items: center;
        position: absolute;
        transform: none;
        border-radius: 0;
        width: 50px;
        height: 50px;
        top: calc(100% + 1.5rem);

        &::after {
            content: "";
            width: 12px;
            height: 20px;
            display: inline-flex;
            background-repeat: no-repeat;
            background-size: contain;
        }

        > .flickity-button-icon {
            display: none;
        }

        &.previous {
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00082 20L0.421875 18.4L8.82187 10L0.421875 1.6L2.00082 0L12.0008 10L2.00082 20Z' fill='%23151f2c'/%3E%3C/svg%3E%0A");
                transform: scale(-1);
            }
        }

        &.next {
            left: 4rem;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00082 20L0.421875 18.4L8.82187 10L0.421875 1.6L2.00082 0L12.0008 10L2.00082 20Z' fill='%23151f2c'/%3E%3C/svg%3E%0A");
            }
        }

        @include media("<lg") {
            display: none;
        }
    }
}
