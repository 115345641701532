.topic-teaser-small {
  @include fade-up-animation;

  display: grid;
  background-color: $palette-white;
  color: $color-text-default;
  z-index: 1;
  overflow: hidden;

  @include media("<md") {
    grid-template-columns: #{2 * $grid-outer-margin} 1fr 1fr 3space $grid-outer-margin;
    grid-template-rows: min-content auto;
  }

  @include media(">=md", "<lg") {
    grid-template-columns: 1fr 7space 2fr 7space;
    grid-template-rows: min-content 14space min-content;
  }

  @include media(">=lg") {
    grid-template-columns: 29.275% 29.275% 7space auto 7space;
    grid-template-rows: auto 7space;
  }

  &.-in-viewport::before {
    animation: skew-in $base-transition-duration-long $base-transition-easing forwards;
  }

  &::before {
    content: "";
    background-image: $gradient-gray_light;
    z-index: 2;

    @include media("<md") {
      grid-column: 3 / -2;
      grid-row: 2 / -2;
      transform-origin: bottom left;
    }

    @include media(">=md", "<lg") {
      grid-column: 2 / -1;
      grid-row: 2 / -1;
      transform-origin: bottom left;
    }

    @include media(">=lg") {
      grid-column: 2 / -1;
      grid-row: 1 / -1;
      transform-origin: top right;
    }
  }

  &::after {
    content: "";
    z-index: 2;
    background-image: $gradient-gray-light;

    @include media("<md") {
      grid-column: 3 / -2;
      grid-row: 2 / -2;
    }

    @include media(">=md", "<lg") {
      grid-column: 2 / -1;
      grid-row: 2 / -1;
    }

    @include media(">=lg") {
      display: none;
    }
  }

  &.-no-image {
    @include media(">=md", "<lg") {
      grid-template-columns: 14space 2fr 7space;
      grid-template-rows: 1fr;
    }

    &:before {
      @include media(">=lg") {
        grid-column: 1 / -1;
      }
    }

    > .content {
      grid-column: 2 / -2;

      @include media(">=md", "<lg") {
        grid-column: 3 / -3;
        padding: 3.5rem 2rem;
      }

      > .title {
        @include media("<md") {
          margin: 0 0 0.5rem;
        }

        @include media(">=md", "<lg") {
          margin: 0 0 2rem;
        }

        @include media(">=lg") {
          margin: 0 0 2rem;
        }
      }

      > .text {
        margin: 0;
      }

      > .actions {
        @include media("<md") {
          margin-top: 1.6rem;
        }

        @include media(">=md") {
          margin-top: 2.6rem;
        }
      }
    }
  }

  > .image {
    z-index: 3;
    width: 100%;

    @include media("<md") {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }

    @include media(">=md", "<lg") {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
    }

    @include media(">=lg") {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
  }

  > .content {
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media("<md") {
      grid-column: 2 / -3;
      grid-row: 2 / -2;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    @include media(">=md", "<lg") {
      grid-column: 3 / 4;
      grid-row: 3 / -1;
      padding-top: 7rem;
      padding-bottom: 7rem;
    }

    @include media(">=lg") {
      grid-column: 4 / -2;
      grid-row: 1 / 2;
    }

    > .title {
      @extend .h4;

      margin-top: 0;

      @include media("<md") {
        margin-bottom: 2.25rem;
      }

      @include media(">=md") {
        margin-bottom: 2.625rem;
      }
    }

    > .actions {
      @include media("<md") {
        margin-top: 3rem;
      }

      @include media(">=md") {
        margin-top: 3.5rem;
      }
    }
  }

  &.-textleft {

    &.-in-viewport::before {
      animation: skew-in-negative $base-transition-duration-long $base-transition-easing forwards;
    }

    @include media("<md") {
      grid-template-columns: #{2 * $grid-outer-margin} 1fr 1fr 3space $grid-outer-margin;
      grid-template-rows: min-content auto;
    }

    @include media(">=md", "<lg") {
      grid-template-columns: 7space 2fr 7space 1fr;
      grid-template-rows: min-content 14space min-content;
    }

    @include media(">=lg") {
      grid-template-columns: 7space auto 7space 29.275% 29.275%;
      grid-template-rows: auto 7space;
    }

    &::before {
      transform-origin: top left;
      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h100v100H0z' fill='%23000000' fill-rule='nonzero'/%3E%3C/svg%3E");
      mask-position: top right;
      mask-repeat: no-repeat;
      mask-size: cover;

      @include media("<md") {
        grid-column: 1 / 3;
        grid-row: 2 / -2;
      }

      @include media(">=md", "<lg") {
        grid-column: 1 / 5;
        grid-row: 2 / -1;
      }

      @include media(">=lg") {
        grid-column: 1 / 5;
        grid-row: 1 / -1;

      }
    }

    &::after {
      @include media("<md") {
        grid-column: 1 / 2;
        grid-row: 2 / -2;
      }

      @include media(">=md", "<lg") {
        grid-column: 1 / 2;
        grid-row: 2 / -1;
      }

      @include media(">=lg") {
        display: none;
      }
    }

    > .image {
      @include media("<md") {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
      }

      @include media(">=md", "<lg") {
        grid-column: 2 / -1;
        grid-row: 1 / 3;
      }

      @include media(">=lg") {
        grid-column: 4 / span 2;
        grid-row: 1 / 2;
      }
    }

    > .content {
      @include media("<md") {
        grid-column: 2 / -3;
        grid-row: 2 / -2;
        padding-top: 3rem;
        padding-bottom: 3rem;
      }

      @include media(">=md", "<lg") {
        grid-column: 2 / 3;
        grid-row: 3 / -1;
        padding-top: 7rem;
        padding-bottom: 7rem;
      }

      @include media(">=lg") {
        grid-column: 2 / span 1;
        grid-row: 1 / 2;
      }
    }
  }
}
