.form-feedback {
  box-shadow: 0 0 0 1px currentColor;
  padding: 1.5space 2space;

  &.-error {
    background-color: rgba($palette-red, 0.1);
    color: $palette-red;
  }

  &.-success {
    background-color: rgba($palette-green, 0.1);
    color: $palette-green;
  }
}
