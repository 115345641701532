@keyframes stba-height-in-sm {
  to {
    height: 78px;
  }
}

@keyframes stba-height-in-md {
  to {
    height: 111px;
  }
}

@mixin topic-teaser-big-prototype {
  $stba-bg-color: mix($palette-white, lighten($palette-gray, 30%));

  @extend %grid;

  position: relative;
  z-index: 1;
  background: $stba-bg-color;

  // The orange clip
  &.-in-viewport::after {
    @include visual-clip;
  }
}

@import "topic-teaser-big/topic-teaser-big-a";
@import "topic-teaser-big/topic-teaser-big-b";
@import "topic-teaser-big/topic-teaser-big-c";
