.figure-item {
  @include media("<md") {
    padding: $grid-outer-margin;
    width: 100%;
  }

  @include media(">=md") {
    max-width: 345px;
  }

  > .icon {
    width: 125px;
    height: 125px;
    background-color: $palette-blue;
    margin-left: -10px;
    mask-repeat: no-repeat;

    @include media("<md") {
      margin-bottom: 3space;
    }

    @include media(">=md") {
      margin-bottom: 3.5space;
    }
  }

  > .title {
    @extend .h4;
  }

  > .text {
    @include media("<md") {
      margin-top: 1.5space;
    }

    @include media(">=md") {
      margin-top: 1.75space;
    }
  }
}
