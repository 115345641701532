// Set a margin for the container sides.
// @todo REFACTOR!
$grid-outer-margin: 1rem !default;

// Sets the breakpoints for the grid.
$grid-breakpoints: map-merge((xs: null), $breakpoints);

// Set maximum width for the wrapper.
$grid-max-width: 1100px !default;

// Configuration for a vertical “rhythym” or system.
$vertical-factors: 0, 1, 2, 3, 4, 5, 6, 5, 6, 7, 8 !default;
$vertical-spacing: 8px !default;
$vertical-properties: (m: margin, p: padding) !default;
$vertical-sides: (t: top, b: bottom) !default;
$vertical-breakpoints: $grid-breakpoints !default;
