.author-box {
  @include media(">=md") {
    display: flex;
    align-items: center;
  }

  &.-border-bottom {
    border-bottom: 3px solid $palette-black-20;

    @include media("<md") {
      padding-bottom: 5space;
    }

    @include media(">=md") {
      padding-bottom: 3.5space;
    }
  }

  > .image {
    @include media("<md") {
      width: 123px;
      margin-bottom: 1.5space;
    }

    @include media(">=md") {
      width: 163px;
      margin-right: 2.625space;
    }
  }

  > .content {
    > .author {
      @extend %body-text-large;
      @extend strong;
    }

    > .label,
    > .role,
    > .links {
      @extend %body-text-extra-small;
    }

    > .links {
      margin-top: 1.75space;

      > .link {
        color: $palette-blue;
      }
    }
  }
}
