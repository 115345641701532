.form-radio {
  display: block;

  &.-inline {
    display: inline-block;

    @include media("<md") {
      margin-right: 3space;
    }

    @include media(">=md") {
      margin-right: 3.5space;
    }
  }

  > .input:checked,
  > .input:not(:checked) {
    position: absolute;
    left: -9999px;

    & + .label {
      position: relative;

      display: inline-block;

      font-weight: 700;

      color: $color-text-default;

      cursor: pointer;

      @include media("<md") {
        padding-left: 3.75space;
      }

      @include media(">=md") {
        padding-left: 4.375space;
      }
    }

    & + .label::before {
      background-color: $palette-white;
    }

    & + .label::before,
    & + .label::after {
      @include pseudo();

      top: 3px;
      left: 0;

      box-sizing: border-box;

      width: 23px;
      height: 23px;

      border-color: $palette-black;

      border-style: solid;
      border-width: 1px;
      border-radius: 50%;

      transition: all $base-transition-duration $base-transition-easing;
    }

    & + .label::after {
      top: 10px;
      left: 7px;
      width: 9px;
      height: 9px;
      background-color: $palette-black;
      border: none;
      transform: scale(0.01);
      opacity: 0;
    }

    & + .label + .message {
      @include media("<md") {
        padding-left: 3.75space;
      }

      @include media(">=md") {
        padding-left: 4.375space;
      }
    }
  }

  > .input:checked + .label {
    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:not(.-inline) + &:not(.-inline) {
    @include media("<md") {
      margin-top: 1.5space;
    }

    @include media(">=md") {
      margin-top: 1.75space;
    }
  }

  > .input.focus-visible + .label {
    @include focus;
  }

  // Error state
  &.-error {
    > .input:not(:checked) + .label::before {
      border-color: $color-error;
    }

    > .input:not(:checked) + .label + .message {
      color: $color-error;
    }
  }
}
