.page-header {
  @extend %grid;

  color: $palette-white;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-header;

  &.-cover::before {
    opacity: 1;
  }

  // Curtain
  &::before {
    background-color: #2c3541; // @todo Use proper var.
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition-duration: $header-transition;
    transition-property: transform, opacity;
    width: 100%;
    z-index: -1;
  }

  > .content {
    display: grid;

    > .home {
      display: flex;
      grid-area: logo;
      pointer-events: all;
    }

    > .menu {
      @include gd-button-plain;

      align-items: center;
      font-weight: bold;
      grid-area: menu;
      margin-left: auto;
      pointer-events: all;
      user-select: none;

      > .label {
        margin-right: 2space;
      }
    }

    > .navigation {
      user-select: none;
    }
  }

  @include media("<md") {
    > .content {
      > .home {
        padding-top: 3.25space;
        width: 110px;
      }

      > .menu {
        margin-top: 3.25space;

        > .label {
          display: none;
        }
      }

      > .navigation {
        padding-top: 6.875space;
        padding-bottom: 3space;
      }
    }
  }

  @include media(">=md", "<lg") {
    > .content {
      > .home {
        padding-top: 5.125space;
        width: 159px;
      }

      > .menu {
        margin-top: 5.875space;
      }

      > .navigation {
        padding-top: 16.25space;
        padding-bottom: 3space;
      }
    }
  }

  @include media("<lg") {
    min-height: 100%;
    pointer-events: none;

    &::before {
      height: 100%;
      top: 0;
      transform: scale(0.9);
      transition-delay: $header-transition;
    }

    &.-cover {
      &::before {
        transform: none;
        transition-delay: 0s;
      }

      > .content {
        pointer-events: all;

        > .navigation,
        > .teaser {
          opacity: 1;
          transition-delay: $header-transition;
          visibility: visible;
        }
      }
    }

    > .content {
      grid-template-areas: "logo menu" "navigation navigation" "teaser teaser";
      grid-template-rows: repeat(3, max-content);

      > .navigation,
      > .teaser {
        opacity: 0;
        transition-delay: 0s;
        transition-duration: $header-transition;
        transition-property: visibility, opacity;
        visibility: hidden;
      }

      > .navigation {
        grid-area: navigation;
        margin-left: auto;
        margin-right: auto;
        max-width: 460px;
        width: 100%;
      }

      > .menu {
        font-size: 14px;
      }

      > .teaser {
        padding-top: 7.125space;
      }
    }
  }

  @include media(">=lg") {
    &::before {
      height: 100vh;
      top: 100%;
    }

    > .content {
      grid-template-areas: "logo navigation" "content content";
      grid-template-columns: max-content auto;

      > .home {
        padding: 4.5space 0;
        width: 155px;
      }

      > .menu {
        display: none;
      }

      > .navigation {
        align-items: flex-end;
        grid-area: navigation;
        margin-left: 135px;
      }

      > .teaser {
        display: none;
      }
    }
  }

  @include media("print") {
    > .content {
      > .home {
        margin: 1rem 0;
      }

      > .home::after,
      > .menu,
      > .navigation {
        display: none;
      }
    }
  }
}
