.table-grid {
  position: relative;
  display: grid;
  gap: 2px 0;
  width: 100%;
  overflow-x: auto;
  background-color: $color-table-head-background;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  > * {
    display: grid;
    gap: 0.5ch;
    grid-auto-flow: column;
    align-items: top;
    padding: 2space 1.5space;

    > * {
      margin: 0;
    }

    &.head {
      background-color: $color-table-head-background;
      font-size: 16px;
      font-weight: bold;
      align-items: center;

      > .tooltip {
        margin-right: 1space;
      }

      @include media(">=md") {
        font-size: 18px;
      }
    }
    &.body {
      background-color: $color-table-background;
      font-size: 14px;
      @include media(">=md") {
        font-size: 16px;
      }
    }
    &.-sticky {
      position: sticky;
      left: 0;
      padding-left: 32px;
      white-space: break-spaces;
      @include media(">=lg") {
        padding-left: 48px;
      }
    }
    &.-shadow {
      box-shadow: inset -2px 0px 0px 0px $color-table-head-background;
    }
    &.-last {
      padding-right: 32px;
      @include media(">=md") {
        padding-right: 48px;
      }
    }
    &.-center {
      justify-items: center;
    }
    &.-bold {
      > * {
        font-weight: bold;
      }
    }
  }
}
