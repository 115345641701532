.video-gallery-item {
    min-height: 100%;
    margin-right: 17px;

    &:last-child {
    }

    @include media("<md") {
        width: calc(70% - 14px);
    }

    @include media(">=md", "<lg") {
        width: calc(30% - 14px);
    }

    @include media(">=lg") {
        width: calc(20% - 14px);
    }
}
