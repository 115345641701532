/*
 * For standard pseudo element attributes.
 * Can be used without any argument: @include pseudo;
 * ...or with arguments: @include pseudo(flex, fixed, 'prefix: ');
 */
@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

/*
 * Orange clip (visual element)
 * If you need it bottom aligned, just pass true in
 */
@mixin visual-clip($bottom: false) {
  @include pseudo();

  left: 50%;
  height: 0; // 110px
  background: $palette-orange;
  animation-duration: $base-transition-duration-long;
  animation-timing-function: $base-transition-easing;
  animation-fill-mode: forwards;
  animation-delay: $base-transition-duration-long;

  @if $bottom {
    bottom: 0;
    transform: translateX(-50%) translateY(50%) skew($base-skew-angle);
  } @else {
    top: 0;
    transform: translateX(-50%) translateY(-50%) skew($base-skew-angle);
  }

  @include media("<md") {
    width: 2px;
    animation-name: stba-height-in-sm;
  }

  @include media(">=md") {
    width: 3px;
    animation-name: stba-height-in-md;
  }
}

/*
 * Fade-up animation
 * Don't forget to set data-in-viewport on the element as well
 */
@mixin fade-up-animation {
  &[data-in-viewport] {
    opacity: 0;

    &.-in-viewport {
      animation: fade-up $base-transition-duration-long $base-transition-easing forwards;
    }
  }
}

/*
 * Mimic native focus
 * https://ghinda.net/article/mimic-native-focus-css/
 */
@mixin focus {
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
  outline-offset: -3px;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}
