.article-teaser-large-list {
  @include media("<md") {
    &::after {
      content: "flickity";
      display: none;
    }

    > .flickity-viewport > .flickity-slider > .item {
      width: 100%;
    }
  }

  @include media(">=md", "<lg") {
    &::after {
      content: "flickity";
      display: none;
    }

    > .flickity-viewport > .flickity-slider > .item {
      width: 50%;

      & + .item {
        margin-left: 3.5space;
      }
    }
  }

  @include media(">=lg") {
    &:not(.-carousel-on-large) {
      display: grid;
      grid-gap: 3.5space 14space;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.-carousel-on-large {
    @include media(">=lg") {
      &::after {
        content: "flickity";
        display: none;
      }

      > .flickity-viewport > .flickity-slider > .item {
        width: 50%;

        & + .item {
          margin-left: 3.5space;
        }
      }

      /* stylelint-disable */
      .flickity-prev-next-button.previous {
        transform: translateX(-200%);
      }

      .flickity-prev-next-button.next {
        transform: translateX(200%);
      }
      /* stylelint-enable */
    }
  }
}
