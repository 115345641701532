.content-hub-article {
  @extend %grid;

  background-color: $palette-white;

  @include media("<md") {
    grid-template-rows: repeat(4, min-content);
    padding-top: 6space;
    padding-bottom: 6space;
  }

  @include media(">=md", "<lg") {
    grid-template-rows: repeat(3, min-content);
    padding-top: 7space;
    padding-bottom: 7space;
  }

  @include media(">=lg") {
    grid-template-rows: repeat(3, min-content);
    padding-top: 7space;
    padding-bottom: 7space;
  }

  > .authorboxsmall {
    grid-row: 1 / 2;

    @include media("<md") {
      margin-bottom: 4space;
    }

    @include media(">=md", "<lg") {
      grid-column: grid-start / 15;
    }

    > .label,
    > .readingtime {
      @extend %body-text-small;
    }

    > .author {
      @extend %body-text-large;
      @extend strong;

      margin: 0;
    }
    @include media("print") {
      display: none;
    }
  }

  > .articlesharelinks {
    @include media("<md") {
      grid-row: 3 / 4;
      margin: 2space 0;
    }

    @include media(">=md", "<lg") {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-row: 1 / 2;
      grid-column: 7 / grid-end;
    }

    @include media(">=lg") {
      grid-row: 2 / -1;
      grid-column: grid-start / 6;
      margin-top: 6space;
    }

    @include media("print") {
      display: none;
    }
  }

  > .content {
    grid-row: 2 / 3;

    @include media(">=md", "<lg") {
      grid-column: 4 / -4;
      margin-top: 6space;
    }

    @include media(">=lg") {
      grid-column: 6 / -6;
      margin-top: 6space;
    }

    > .title {
      @extend .h5;
    }

    /* stylelint-disable */
    .quote-box {
      margin: 4rem 0;

      blockquote {
        max-width: none;
      }
    }
    /* stylelint-enable */

    @include media("print") {
      grid-column: 2 / -2;
    }
  }

  > .authorbox {
    @include media("<md") {
      margin-top: 4space;
      grid-row: 4 / -1;
    }

    @include media(">=md", "<lg") {
      margin-top: 4space;
      grid-column: 4 / -4;
    }

    @include media(">=lg") {
      margin-top: 6space;
      grid-row: 3 / -1;
      grid-column: 6 / -6;
    }
    @include media("print") {
      grid-column: 2 / -1;
      grid-row: 4 / -1;
    }
  }
}
