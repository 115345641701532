.text-block {
  > * {
    &:first-child {
      margin-top: 0;
    }
  }

  > p {
    @include media("<md") {
      margin: 0;
    }

    @include media(">=md") {
      margin: 0 0 1rem 0;
    }
  }

  > p + p {
    margin: 1rem 0;
  }

  > h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5rem 0;
  }

  > h2 ~ h2,
  > h2 ~ h3,
  > h2 ~ h4,
  > h2 ~ h5,
  > h2 ~ h6,
  > h3 ~ h3,
  > h3 ~ h4,
  > h3 ~ h5,
  > h3 ~ h6,
  > h4 ~ h3,
  > h4 ~ h5,
  > h4 ~ h6,
  > h5 ~ h5,
  > h5 ~ h6 {
    @include media("<md") {
      margin-top: 1rem;
    }

    @include media(">=md") {
      margin-top: 1.5rem;
    }
  }

  > p + h2,
  > p + h3,
  > p + h4 {
    @include media("<md") {
      margin-top: 3.5rem;
    }

    @include media(">=md") {
      margin-top: 4.5rem;
    }
  }

  > p + h5,
  > p + h6 {
    @include media("<md") {
      margin-top: 1.5rem;
    }

    @include media(">=md") {
      margin-top: 2.25rem;
    }
  }

  > h5, h6 {
    @include media("<md") {
      font-size: 1rem;
    }
  }
}
