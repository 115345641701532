.article-share-links {
  list-style: none;
  margin: 0;
  padding: 0;

  > .item {
    @include media("<lg") {
      display: inline-block;
      margin-right: 1.75space;
    }

    @include media(">=lg") {
      & + .item {
        margin-top: 1.75space;
      }
    }
  }

  > .item > .link {
    color: $palette-blue;

    @media (hover: hover) {
      &:hover {
        color: darken($palette-blue, 20%);
      }
    }
  }

  > .item > .link > .icon {
    vertical-align: middle;

    @include media("<lg") {
      width: 23px;
    }

    @include media(">=lg") {
      width: 26px;
    }
  }
}
