.donut-chart {
  margin: 0;

  > .chart {
    margin: 2space auto;
    width: 80%;
  }

  > .legend {
    margin: 0;
    padding: 0;

    > .item {
      align-items: center;
      display: flex;
      padding: 0.5space 0;

      &:not(:last-child) {
        box-shadow: 0 1px 0 $palette-black-20;
      }

      > .color {
        min-height: 20px;
        margin-right: 8px;
        min-width: 20px;
      }

      > .value {
        margin-right: 0.5space;
        min-width: 50px;
      }
    }
  }
}
