.link-large {
  @extend .h4;

  color: inherit;
  position: relative;
  text-decoration: none;

  @include media("<md") {
    padding-right: 6space;
  }

  @include media(">=md") {
    padding-right: 7space;
  }

  &::after {
    @include pseudo();

    display: inline-block;
    width: 17px;
    height: 1em;
    background-color: currentColor;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.318 29L0 26.68 12.333 14.5 0 2.32 2.318 0 17 14.5z' fill='%23000000' fill-rule='nonzero'/%3E%3C/svg%3E");
    mask-position: right center;
    mask-repeat: no-repeat;
    mask-size: contain;
    margin-left: 0.5em;
    transition: transform $base-transition-duration $base-transition-easing;
  }

  @media (hover: hover) {
    &:hover::after {
      transform: translateX(4px);
    }
  }
}
