.key-value-list {
  $separator-style: 3px solid rgba($palette-lilac, 0.15);

  @extend %body-text-small;

  display: grid;
  grid-template: auto / auto auto;
  margin-top: 0;
  margin-bottom: $vertical-spacing * 1.5;

  > .key {
    hyphens: auto;
    padding-right: $vertical-spacing;

    &:not(:first-child) {
      border-top: $separator-style;
    }
  }

  > .value {
    font-weight: bold;
    padding-left: $vertical-spacing;
    text-align: right;

    &:not(:nth-child(2)) {
      border-top: $separator-style;
    }
  }

  > .key,
  > .value {
    margin: 0;
    padding-bottom: $vertical-spacing;
    padding-top: $vertical-spacing;
  }
}
