.form-dropdown {
  display: block;

  > .label {
    color: currentColor;
    display: block;
  }

  > .dropdown {
    position: relative;

    &::after {
      border-style: solid;
      border-width: 8px 6px;
      border-color: currentColor transparent transparent transparent;
      color: $palette-blue;
      content: "";
      position: absolute;
      right: 0.75em;
      top: 50%;
      transform: translateY(-25%);
    }

    > .placeholder,
    > .select {
      font-weight: bold;

      @include media("<md") {
        padding: 1.5space 2.25space;
      }

      @include media(">=md") {
        padding: 1.75space 2.625space;
      }
    }

    > .placeholder {
      color: currentColor;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      user-select: none;
      width: 100%;
    }

    > .select {
      appearance: none;
      background-color: transparent;
      border-radius: 0;
      border: 0;
      box-shadow: inset 0 -2px 0 $palette-blue, inset 0 0 0 1px $palette-light-blue;
      color: currentColor;
      display: block;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      width: 100%;

      &:valid + .placeholder {
        display: none;
      }
    }
  }
}
