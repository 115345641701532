@include gd-base;

:root {
  --header-breakpoint: #{map-get($breakpoints, $header-breakpoint)};

  background-color: $palette-white;
  color: $palette-black;
  scroll-behavior: smooth;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-print-color-adjust: exact !important;
}

address {
  font-style: normal;
}

p a,
li a {
  &:not([class]) {
    color: $palette-blue;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: darken($palette-blue, 20%);
    }
  }
}

[x-cloak],
[hidden] {
  display: none !important;
}

[data-slideshow] {
  opacity: 0;
  height: 0;
}
