.scenario-chart {
  background-color: rgba(#d0d2d5, 0.12); // @todo Color is design inconsistency.
  // overflow: hidden;
  // margin-top: 4space;

  // @include media(">=lg") {
  //   display: flex;
  //   flex-wrap: wrap;
  //   padding-bottom: 3space;
  // }

  display: grid;
  grid-template-columns: 2fr 1fr;


  > .chart {
    display: grid;
    grid-column: 1/2;
    @include media("<md") {
      grid-column: 1/-1;
    }
    > #container {
      width: 100%;
      height: 100%;
    }
  }

  > .assets {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media("<md") {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    > .asset {
      @include gd-button-plain;

      padding: 1space 1.75space 1.25space;
      position: relative;
      text-align: center;

      @include media("<md") {
        margin-right: 1.625space;
        margin-bottom: 1.625space;
      }

      @include media(">=md") {
        margin-right: 2space;
        margin-bottom: 2space;
      }

      &::before {
        content: attr(title);
        font-weight: bold;
        opacity: 0;
      }

      &.-active {
        color: $palette-white;
        font-weight: bold;
      }

      &:not(.-active) {
        box-shadow: inset 0 0 0 1px currentColor;
        color: $palette-black-20;
      }

      > .label {
        left: 50%;
        padding: inherit;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
      }

      @include media("print") {
        display: none;
      }
    }
  }

  > .subtitle {
    background-color: $palette-white;
    width: 100%;
    grid-column: 1 / -1;
    margin: 0;
    padding-left: 1space;
  }

  > .timespans {
    display: flex;

    @include media("<md") {
      display: block;
      white-space: nowrap;
      overflow-x: scroll;
      padding-bottom: 1space;
    }

    width: 100%;

    // &.-range {
    //   padding-top: 1rem;
    //   display: grid;
    //   width: 100%;
    //   grid-template-columns: repeat(4, minmax(min-content, max-content));
    //   gap: 0 2space;
    //   > .title {
    //     background-color:$palette-white;
    //     margin: 0;
    //     padding-left: 0.8rem;
    //     grid-column: 1 / -1;
    //   }
    // }

    > .timespan {
      @include gd-button-plain;
      width: auto;
      align-items: center;
      color: $palette-blue;
      padding: 1.75space 2space;
      position: relative;

      > .arrow {
        width: 14px;
        margin-left: 8px;
      }

      &:hover,
      &.-active {
        box-shadow: inset 0 -2px 0 currentColor;
      }

      &.-active {
        font-weight: bold;
      }

      > .dropdown {
        position: absolute;
        appearance: none;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }

      @include media("<md") {
        padding: 2space 1space;
        white-space: nowrap;
      }

      @include media("print") {
        padding: 0;
        &.-active {
          font-weight: unset;
        }
        &:not(.-active) {
          display: none;
        }
      }
    }
  }

  > .timerange {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(min-content, max-content));
    gap: 1space;
    padding: 2rem 0;
  }

  > .assets,
  > .timespans,
  > .timerange {
    grid-column: 1 / -1;
  }

  > .assets,
  > .timespans,
  > .timerange,
  > .summary {
    background-color: $palette-white;
  }

  > .chart {
    margin-top: 2space;
    @include media(">=md") {
      padding: 3space;
    }
  }

  > .summary {
    grid-column: 2 / 3;
    margin: 2space;
    @include media("<md") {
      grid-column: 1/-1;
    }
    // display: flex;
    // flex-wrap: wrap;
    // flex: 1 1 auto;
    // justify-content: flex-start;
    // margin: 1space;

    // @include media(">=lg") {
    //   align-self: center;
    //   flex-direction: column;
    //   justify-content: center;
    //   margin: 3.25space;
    //   min-width: 300px;
    // }

    > .title {
      display: inline-flex;
      flex-wrap: wrap;
      background-color: $palette-white;
      margin: 0;
      padding: 2.5space;
      width: 100%;

      @include media(">=lg") {
        padding: 4.75space 4.75space 2.5space;
      }
    }

    > .asset {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0.5space 2.5space;
      width: 100%;

      // @include media(">=md", "<lg") {
      //   width: 50%;
      // }

      @include media(">=lg") {
        padding-left: 4.75space;
        padding-right: 4.75space;
      }

      &:last-child {
        padding-bottom: 1.5rem;
      }

      &.-active > .value {
        font-weight: bold;
      }

      &:not(.-active) {
        color: $palette-black-20;

        > .label::before {
          background-image: none;
          box-shadow: 0 0 0 1px currentColor;
        }

        > .value {
          display: none;
        }
      }

      > .label {
        align-items: center;
        background-size: 0;
        display: flex;

        &::before {
          background-image: inherit;
          content: "";
          height: 10px;
          margin-right: 10px;
          width: 10px;
        }
      }
    }

  }

}
