// @see https://necolas.github.io/normalize.css/
// @see https://github.com/csstools/postcss-normalize
@import-normalize;

// @see http://include-media.com/
@import "~include-media/dist/include-media";

// @see https://github.com/waldemarfm/sass-svg-uri
//@import "~sass-svg-uri/svg-uri";

// @see https://github.com/gridonic/grid
//@import "~@gridonic/grid";

// @see https://github.com/gridonic/components
@import "~@gridonic/components/styles";

// @see https://swiperjs.com/
@import "vendor/hamburgers";

// @see https://flickity.metafizzy.co/
@import "~flickity/dist/flickity.css";

// @see https://atomiks.github.io/tippyjs/
@import "~tippy.js/dist/tippy.css";

.highcharts-legend-item {
    > path {
        height: 10px;
    }
}

.highcharts-legend-box {
	fill: $palette-white;
	fill-opacity: 1;
	stroke-width: 0px;
}
.highcharts-tooltip {
    background-color: red;
    //padding: 1space;
}
.highcharts-tooltip-box {
    background-color: blue;
    //padding: 1space;
}
.highcharts-legend-item {
    width: 100%;
}
.flickity-page-dots {
  bottom: 0!important;
  line-height: 0!important;
  margin-top: $vertical-spacing * 6!important;
  position: static!important;

  @include media(">=md") {
    margin-top: $vertical-spacing * 8!important;
  }

  > .dot {
    position: relative!important;
    background-color: $palette-gray!important;
    border-radius: 0!important;
    height: 3px!important;
    margin: 0 ($vertical-spacing * 0.5)!important;
    opacity: 1!important;
    width: 30px!important;

    &::before {
      display: block!important;
      position: absolute!important;
      content: ""!important;
      width: 100%!important;
      height: 30px!important;
      transform: translateY(-50%)!important;
    }

    &.is-selected {
      background-color: $palette-orange!important;
    }
  }
}
.flickity-viewport {
  @include media("print") {
    height: auto !important;
  }
}
.flickity-slider {
  @include media("print") {
    display: flex !important;
    flex-wrap: wrap !important;
    position: static !important;
    transform: none !important;
  }
}

