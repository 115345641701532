.nav-list {
  overflow: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.nav-link {
  &.-head {
    font-size: 42px;
    font-weight: bold;
    cursor: pointer;
  }

  &.-current {
    color: orange;
    font-weight: bold;
  }

  &:hover {
    color: orange;
  }

  > .link {
    text-decoration: none;
  }

  @include media("<=lg") {
    font-size: 20px;
    &.-head {
      font-size: 28px;
      font-weight: bold;
    }
  }

  @include media(">lg") {
    &.-head {
      font-weight: medium;
      font-size: unset;
    }
    &.-topic {
      font-size: 36px;
      font-weight: bold;

      > .link {
        letter-spacing: 0.6px;
        line-height: 50px;
      }
    }
    &.-page {
      font-size: 22px;
    }
  }
}

@include media(">lg") {
  .nav-list-wrapper {
    &.-top {
      height: 100%;
      color: white;

      > .list {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, minmax(0, auto)) 1fr;
        gap: 12space;
        height: 100%;

        > .item {
          display: inline-flex;
          height: 100%;
          align-items: center;

          &.-selectedCategory {
            box-shadow: inset 0 -4px 0 0 orange;

            > .nav-link {
              color: orange;
            }
          }

          &.-active {
            box-shadow: inset 0 -4px 0 0 orange;
          }
        }

        > .language {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      &.-inverted {
        color: black;
      }
    }

    &.-second {

      position: absolute;
      top: 100%;
      left: 0;
      right: 0;

      display: grid;
      width: 100%;
      grid-template-rows: 0;
      padding: 6.25space;

      max-width: calc(#{$grid-max-width} + 26space);
      margin: 0 auto;

      max-height: calc(100vh - 89px);
      overflow-y: auto;

      &::before {
        @include pseudo();
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-color: #a1a5ab;
        opacity: 0.1;
        z-index: 0;
      }

      &::after {
        @include pseudo();
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #356693;
        opacity: 0.05;
        z-index: 0;
        mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 0,0 L 50,0 L 100,100 L 0 100 Z' fill='%23000000' fill-rule='nonzero'/%3E%3C/svg%3E");
        mask-position: top left;
        mask-repeat: no-repeat;
        mask-size: contain;
        //animation: fade-up $base-transition-duration-long $base-transition-easing infinite;
      }

      > .close {
        position: absolute;

        visibility: hidden;
        opacity: 0;
        height: 0;
        border: 0;
        padding: 0;
        cursor: pointer;

        &.-visible {
          background-color: transparent;
          color: $palette-gray;
          border: 0;

          top: 6space;
          right: 6space;

          padding: 2space;

          position: absolute;
          visibility: visible;
          opacity: 1;
          height: auto;
          z-index: 2;
          transition-duration: $base-transition-duration;
          transition-timing-function: $base-transition-easing;
          transition-property: opacity;
        }
      }

      > .list {

        width: 100%;
        grid-template-rows: 0;
        background-size: 100% 0%;
        visibility: hidden;
        opacity: 0;

        z-index: 1;

        display: inline-grid;
        grid-gap: 1px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

        &.-one-third {
          grid-template-columns: 1fr 2fr;
        }

        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        justify-content: space-between;


        transition-duration: 0.25s;
        transition-delay: 0.25s;
        transition-timing-function: ease-in-out;
        transition-property: opacity;

        > .mobiletitle {
          display: none;
        }

        > .item {
          background-color: white;
          padding: 6.625space;

          > .title {
            font-weight: bold;
            font-size: 42px;
            display: block;
          }

          > .list {
            margin-top: 2space;
          }

          > .teaser {
            margin-top: 4space;
          }
        }

      }

      visibility: hidden;
      opacity: 0;

      background-image: linear-gradient(rgba(white, 0.9), rgba(white, 0.9));
      background-repeat: no-repeat;
      background-size: 100% 90%;
      transition-duration: 0.25s;
      transition-timing-function: ease-in-out;
      transition-property: background-size;

      &.-visible {
        grid-template-rows: min-content;
        visibility: visible;
        opacity: 1;
        background-size: 100% 100%;
        transition-property: background-size;

        > .list {
          grid-template-rows: min-content;
          background-size: 100% 100%;
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.-third {
      > .list {
        > .mobiletitle {
          display: none;
        }

        > .item {
          margin-top: 3space;

          &:nth-of-type(2) {
            margin-top: 4space;
          }
        }
      }
    }

  }
}

@include media("<=lg") {
  .nav {
    position: relative;
  }
  .nav-list-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;

    visibility: hidden;
    z-index: -1;

    padding: 2space;

    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;

    transform: translateX(0);

    background-color: $palette-white;

    &::before {
      @include pseudo();
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: #a1a5ab;
      opacity: 0.1;
      z-index: -1;
    }

    &::after {
      @include pseudo();
      display: inline-block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #356693;
      opacity: 0.05;
      z-index: -1;
      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 0,0 L 50,0 L 100,100 L 0 100 Z' fill='%23000000' fill-rule='nonzero'/%3E%3C/svg%3E");
      mask-position: top left;
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    > .close {
      display: none;
    }

    &.-selectedparentlist {
      visibility: hidden;
      opacity: 1;
      z-index: 1;
      left: 100%;
      transform: translateX(-100%);

      > .item {
        pointer-events: none;
      }
    }

    &.-selected {
      opacity: 1;
      z-index: 1;
      visibility: visible;
      left: -100%;
      transform: translateX(100%);

      > .list {
        display: flex;
        flex-direction: column;

        > .item {
          pointer-events: initial;

          &.-has-children {
            &:hover {
              color: orange;
            }
          }
        }
      }
    }
  }

  .nav-list {
    background-color: white;
    height: 100%;

    > .mobiletitle {
      padding: 3space;
      font-weight: bold;
      font-size: 30px;

      > .title {
        > .link {
          text-decoration: none;
        }
      }
    }

    > .item {
      padding: 3space;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.-haschildren {
        &::after {
          display: inline-block;
          content: "";
          width: 17px;
          height: 1em;
          background-color: currentColor;
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.318 29L0 26.68 12.333 14.5 0 2.32 2.318 0 17 14.5z' fill='%23000000' fill-rule='nonzero'/%3E%3C/svg%3E");
          mask-position: right center;
          mask-repeat: no-repeat;
          mask-size: contain;
        }

        > .nav-link {
          &.-topic {
            > .link {
              pointer-events: none;
            }
          }
        }
      }

      &.-additional-content {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    > .language {
      margin-top: auto;
    }
  }
}

.navigation-address {
  display: grid;
  grid-template-columns: 16px 1fr;

  margin-top: 4space;

  > .icon {
    transform: translateY(2px);
  }

  > .content {
    > a {
      margin: 0 0 1space 1.5space;

      > p {
        margin: 0;
      }
    }

    > p {
      margin: 0 0 1space 1.5space;
    }
  }
}
