.screenreader-only {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  border: 0;

  clip: rect(0, 0, 0, 0);

  &.-focusable {
    &:active,
    &:focus {
      position: static;

      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;

      clip: auto;
    }
  }
}
