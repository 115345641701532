.download-list {
  $download-list-border: 1px solid $palette-gray;

  &.-single-column {
    > .item:first-of-type {
      border-top: $download-list-border;
    }

    @include media(">=md") {
      > .title {
        margin-bottom: 6.125space;
      }
    }
  }

  &.-double-column {
    @include media("<md") {
      margin-top: 3space;

      > .item:first-of-type {
        border-top: $download-list-border;
      }
    }

    @include media(">=md") {
      margin-top: 3.5space;

      display: grid;
      grid-column-gap: 7space;
      grid-template-columns: repeat(2, 1fr);

      > .title {
        grid-column: 1 / -1;
        margin-bottom: 6.125space;
      }

      > .item:nth-child(2),
      > .item:nth-child(3) {
        border-top: $download-list-border;
      }
    }
  }

  > .item {
    display: flex;
    align-items: center;
    border-bottom: $download-list-border;

    &:first-child {
      border-top: $download-list-border;
    }
  }

  > .item > .link {
    display: block;
    width: 100%;
    padding-right: 30px;
    text-decoration: none;
    position: relative;
    color: $palette-blue;
    font-weight: 700;
    //hyphens: auto;
    //text-wrap: pretty;

    @include media("<md") {
      padding-top: 1.5space;
      padding-bottom: 1.5space;
    }

    @include media(">=md") {
      padding-top: 1.75space;
      padding-bottom: 1.75space;
    }

    &::after {
      @include pseudo();

      top: 50%;
      right: 0;
      width: 16px;
      height: 22px;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='22' viewBox='0 0 16 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23356693' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M1 10l7.042 6L15 10'/%3E%3Cpath stroke-linecap='square' d='M3 21h10M8 1v14'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
