.comparison-carousel {
  > .next,
  > .previous {
    background-color: transparent;
    color: $palette-white;
    padding: 0;
    margin-top: $vertical-spacing * -3;

    @include media(">=md") {
      margin-top: $vertical-spacing * -4;
    }

    @include media("print") {
      display: none;
    }
  }

  > .next {
    right: 2%;
  }

  > .previous {
    left: 2%;
  }

  > .cell {
    opacity: 0;
  }
}
