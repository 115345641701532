.table-container {
  $table-scroll-border: 3px solid rgba($palette-lilac, 0.15);

  border-spacing: 0;
  min-width: 800px;
  width: 100%;

  font-size: 16px;

  > .head {
    > .row {
      > .cell {
        border-bottom: $table-scroll-border;
        padding: $vertical-spacing * 1.5;
        text-align: left;
      }
    }
  }

  > .body {
    > .row {
      &:not(:last-child) > .cell {
        border-bottom: $table-scroll-border;
      }

      > .cell {
        padding: $vertical-spacing * 1.5;
        text-align: left;

        &:nth-child(2n+3) {
          color: $palette-lilac;
        }
      }
    }
  }

  > .head,
  > .body {
    > .row > .cell {
      &.-center {
        align-items: center;
        justify-content: center;
        text-align: center;

        > .checkbox,
        > .icon {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}
