.language-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;

  @include media("<lg") {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 1.5rem;
  }

  @include media(">=lg") {
    transition: clip-path 150ms;
    clip-path: inset(0 0 0 70%);

    &:hover {
      clip-path: inset(0 0 0 0);
    }
  }

  > .link {
    text-decoration: none;

    @include media(">=lg") {
      display: flex;
      align-items: center;
      color: $palette-orange;
      height: 100%;

      &:hover {
        box-shadow: inset 0 -4px 0 0 orange;
      }
    }

    &:hover {
      color: $palette-orange;
    }

    &.-active {
      display: flex;
      font-weight: bold;
    }
  }
}
