.asset-group-name {
  display: flex;
  flex-direction: column;

  > .title {
    max-width: 20ch;
    hyphens: manual;
  }

  > .isin {
    font-weight: 400;
  }
}
