.link-list {
  margin: 0;
  padding: 0;
  list-style: none;

  > .item {
    & + .item {
      @include media("<md") {
        margin-top: 2.125space;
      }

      @include media(">=md", "<lg") {
        margin-top: 5.75space;
      }

      @include media(">=lg") {
        margin-top: 7.125space;
      }
    }
  }
}
