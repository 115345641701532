.modal-accordion {
  list-style: none;
  margin: 0;
  padding: 0;

  > .item {
    border-bottom: 1px solid rgba(68, 76, 86, 0.5);

    &.-open {
      > .title > .toggle {
        transform: scaleY(-1);
      }
    }

    > .title {
      display: grid;
      grid-template-columns: 1fr 32px;
      align-items: center;
      gap: 1rem;
      font-size: 1rem;
      margin: 0;

      > *:first-child {
        @extend ._mt-xs-4;
        @extend ._mb-xs-4;
      }

      > .toggle {
        appearance: none;
        border: none;
        width: 32px;
        height: 32px;
        background: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.5L1.52 0L9.5 7.98L17.48 0L19 1.5L9.5 11L0 1.5Z' fill='%23171F2B'/%3E%3C/svg%3E") center right no-repeat;
        cursor: pointer;
      }
    }

    > .panel {
      padding-bottom: 1.25rem;
    }
  }
}
