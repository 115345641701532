.button-plain {
  @include gd-button-plain;

  color: $palette-black;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 18px;

  @include media(">=md") {
    font-size: 18px;
    padding: 10px 18px;
  }

  @include media(">=lg") {
    font-size: 20px;
    padding: 14px 18px;
  }
}
