.form-control {
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  > .input,
  > .select,
  > .textarea,
  > .label {
    grid-row-start: 1;
    grid-column: 1;

    font-size: inherit;

    @include media("<md") {
      line-height: 3space;
    }

    @include media(">=md") {
      line-height: 3.5space;
    }
  }

  > .label {
    user-select: none;
  }

  > .select,
  > .textarea {
    grid-row-start: 2;

    resize: none;

    + .label {
      order: 0;
    }
  }

  > .input {
    // Useful states, might need it in the future.
    // &:not(:placeholder-shown),
    // &[pattern]:valid,
    // &[required]:valid

    + .label {
      font-size: 13px;
      color: $palette-blue;
      transform: translateY(-20%);
      transform-origin: left center;
      transition: transform 300ms, font-size 300ms;
      pointer-events: none;

      @include media("<md") {
        padding: 1.5space 2.25space;
      }

      @include media(">=md") {
        padding: 1.75space 2.625space;
      }
    }

    &:disabled + .label {
      color: $palette-gray;

      cursor: not-allowed;
    }

    &:focus + .label {
      pointer-events: none;
    }

    &:not(:focus):placeholder-shown + .label {
      font-size: inherit;

      transform: none;
    }
  }

  > .select {
    appearance: none;
  }

  > .select,
  > .input,
  > .textarea {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: inset 0 -2px 0 $palette-blue, inset 0 0 0 1px $palette-light-blue;
    color: $palette-black;
    width: 100%;
    appearance: none;

    @include media("<md") {
      padding: 2.25space 2.25space 0.75space;
    }

    @include media(">=md") {
      padding: 2.625space 2.625space 0.875space;
    }

    &:focus {
      border-color: $palette-blue;
    }

    &:disabled {
      color: $palette-gray;

      cursor: not-allowed;
    }

    // Never show the placeholder, since we are using the label
    // as a placeholder and a floating label.
    &::placeholder {
      color: transparent;
    }
  }

  // Styles for erroneous user input
  &.-error {
    > .input,
    > .textarea {
      color: $palette-red;

      background-color: mix($palette-red, $palette-white, 10%);
      border-bottom-color: $palette-red;
    }

    > .error {
      color: $palette-red;
      font-size: 13px;

      @include media("<md") {
        padding: 0.75space 2.25space;
      }

      @include media(">=md") {
        padding: 0.875space 2.625space;
      }
    }
  }
}
