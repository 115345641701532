$palette-white: #fff;

$palette-gray: #a1a5ab;
$palette-pale-gray: #f7f8fb;

$palette-black: #151f2c;
$palette-black-80: mix($palette-black, #fff, 80%);
$palette-black-60: mix($palette-black, #fff, 60%);
$palette-black-40: mix($palette-black, #fff, 40%);
$palette-black-20: mix($palette-black, #fff, 20%);

$palette-orange: #ff9e15;
$palette-orange-80: mix($palette-orange, #fff, 80%);
$palette-orange-60: mix($palette-orange, #fff, 60%);
$palette-orange-40: mix($palette-orange, #fff, 40%);
$palette-orange-20: mix($palette-orange, #fff, 20%);

$palette-light-blue: #87c7d7;
$palette-light-blue-80: mix($palette-light-blue, #fff, 80%);
$palette-light-blue-60: mix($palette-light-blue, #fff, 60%);
$palette-light-blue-40: mix($palette-light-blue, #fff, 40%);
$palette-light-blue-20: mix($palette-light-blue, #fff, 20%);

$palette-green: #1a7d76;
$palette-green-80: mix($palette-green, #fff, 80%);
$palette-green-60: mix($palette-green, #fff, 60%);
$palette-green-40: mix($palette-green, #fff, 40%);
$palette-green-20: mix($palette-green, #fff, 20%);

$palette-tan: #e4e6c3;
$palette-tan-80: mix($palette-tan, #fff, 80%);
$palette-tan-60: mix($palette-tan, #fff, 60%);
$palette-tan-40: mix($palette-tan, #fff, 40%);
$palette-tan-20: mix($palette-tan, #fff, 20%);

$palette-blue: #356693;
$palette-blue-80: mix($palette-blue, #fff, 80%);
$palette-blue-60: mix($palette-blue, #fff, 60%);
$palette-blue-40: mix($palette-blue, #fff, 40%);
$palette-blue-20: mix($palette-blue, #fff, 20%);

$palette-lilac: #5c73b5;
$palette-lilac-80: mix($palette-lilac, #fff, 80%);
$palette-lilac-60: mix($palette-lilac, #fff, 60%);
$palette-lilac-40: mix($palette-lilac, #fff, 40%);
$palette-lilac-20: mix($palette-lilac, #fff, 20%);

$palette-grape: #672a4e;
$palette-grape-80: mix($palette-grape, #fff, 80%);
$palette-grape-60: mix($palette-grape, #fff, 60%);
$palette-grape-40: mix($palette-grape, #fff, 40%);
$palette-grape-20: mix($palette-grape, #fff, 20%);

$palette-red: #cc414e;
$palette-red-80: mix($palette-red, #fff, 80%);
$palette-red-60: mix($palette-red, #fff, 60%);
$palette-red-40: mix($palette-red, #fff, 40%);
$palette-red-20: mix($palette-red, #fff, 20%);

$palette-calipso: #356693;


// Semantic names
$color-text-default: $palette-black;
$color-error: $palette-red;


// Gradients
$gradient-gray-light: linear-gradient(to bottom, $palette-white, #f1f1f2 99%);

// Exceptions
$color-table-background: #F5F7FA;
$color-table-head-background: #E0E4F0;
$color-scrollbar-thumb: #9A9CA5;
