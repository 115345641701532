.scrollbar-container {
  position: relative;
  display: grid;
  align-items: center;
  height: 7px;
  width: 100%;
  background-color: $color-table-head-background;

  > .handle {
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    background-color: $color-scrollbar-thumb;
    width: 200px;
    height: 3px;
    border-radius: 8px;
    margin: 0 3px;
  }
}
