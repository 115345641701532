.topic-teaser-big-b {
  @include topic-teaser-big-prototype;

  background-color: $palette-light-blue;
  color: $palette-grape;

  @include media("<md") {
    padding-top: 9space;
    padding-bottom: 9space;
  }

  @include media(">=md", "<lg") {
    padding-top: 18space;
    padding-bottom: 18space;
  }

  @include media(">=lg") {
    padding-top: 22space;
    padding-bottom: 22space;
  }

  > .content {
    @include fade-up-animation;

    @include media(">=md") {
      grid-column: grid;
    }

    @include media(">=lg") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 27.625space;
      align-items: center;
    }

    > .box {
      > .title {
        margin-top: 0;

        @include media("<md") {
          margin-bottom: 1.625space;
        }

        @include media(">=md", "<lg") {
          margin-bottom: 3.875space;
        }

        @include media(">=lg") {
          margin-bottom: 4space;
        }
      }

      > .caption {
        @extend %body-text-extra-large;

        @include media("<md") {
          margin-bottom: 3.75space;
        }

        @include media(">=md", "<lg") {
          margin-bottom: 9.125space;
        }

        @include media(">=lg") {
          margin-bottom: 0;
        }
      }
    }
  }
}
