.video-capture-text {
    @include media("<md") {
        margin-top: 1rem;
    }

    @include media(">=md") {
        margin-top: 2rem;
    }

    > .title {
      @extend .h6;

      margin-bottom: 0.5rem;
    }
}
