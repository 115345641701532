.topic-teaser-big-a {
  @include topic-teaser-big-prototype;

  padding-top: 9.125space;
  padding-bottom: 9.125space;

  &.-in-viewport::before {
    $stba-bg-overlay-color: rgba($palette-blue, 0.05);

    @include pseudo();

    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: $stba-bg-overlay-color;
    transform-origin: bottom right;
    transform: skew($base-skew-angle);
    animation: skew-and-fade-in $base-transition-duration-long $base-transition-easing forwards;
    z-index: -1;
  }

  > .content {
    @include fade-up-animation;
    @extend %grid;

    color: $color-text-default;
    position: relative;
    grid-column: full-bleed;

    &::before {
      background-color: $palette-white;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      @include media(">=lg") {
        left: -10space;
        width: calc(100% + 20space);
      }
    }

    @include media("<md") {
      grid-column: full-bleed;
      margin: 0 1.625space;
      padding: 4.75space 0;
    }

    @include media(">=md", "<lg") {
      padding: 10space 0;

      &::before {
        grid-column: grid;
      }

      > .title,
      > .boxes {
        grid-column: col-start 2 / col-end -2;
      }
    }

    @include media(">=lg") {
      padding: 12space 0;

      &::before {
        grid-column: grid;
      }

      > .title {
        grid-column: grid;
        margin-bottom: 8space;
      }

      > .boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4space;
      }
    }

    > .title {
      @extend .h2;

      margin-top: 0;
      position: relative;
    }

    > .boxes > .box {
      position: relative;

      @include media("<lg") {
        & + .box {
          margin-top: 7.5space;
        }
      }

      > .link {
        @include media("<md") {
          margin-bottom: 1.375space;
        }

        @include media(">=md", "<lg") {
          margin-bottom: 2.625space;
        }

        @include media(">=lg") {
          margin-bottom: 3.75space;
        }
      }

      > .caption {
        @extend %body-text-large;

        margin-top: 0;
      }
    }
  }
}
