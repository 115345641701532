.team-overview {
  @include media("<md") {
    &::after {
      content: "flickity";
      display: none;
    }

    > .flickity-viewport > .flickity-slider > .item { // stylelint-disable-line
      width: 100%;

      & + .item {
        margin-left: 6space;
      }
    }
  }

  @include media(">=md") {
    display: grid;
    grid-gap: 6space;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(">=lg") {
    grid-gap: 14space;
    grid-template-columns: repeat(3, 1fr);
  }
}
