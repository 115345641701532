@each $property-name, $property-value in $vertical-properties {
  @each $side-name, $side-value in $vertical-sides {
    @each $breakpoint-name, $breakpoint-value in $vertical-breakpoints {
      @if $breakpoint-value {
        @include media(">=#{$breakpoint-name}") {
          @each $factor in $vertical-factors {
            ._#{$property-name}#{$side-name}-#{$breakpoint-name}-#{escape-string($factor)} {
              #{$property-value}-#{$side-value}: $factor * $vertical-spacing !important;
            }
          }
        }
      }

      @else {
        @each $factor in $vertical-factors {
          ._#{$property-name}#{$side-name}-#{$breakpoint-name}-#{escape-string($factor)} {
            #{$property-value}-#{$side-value}: $factor * $vertical-spacing !important;
          }
        }
      }
    }
  }
}

@each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
  $from: ">=#{$breakpoint-name}";
  $until: "<#{$breakpoint-name}";

  @if $breakpoint-value != null {

    // Hide from starting breakpoint
    ._hidden-from-#{$breakpoint-name} {
      @include media($from) {
        display: none!important;
      }
    }

    // Hide until breakpoint
    ._hidden-until-#{$breakpoint-name} {
      @include media($until) {
        display: none;
      }
    }

    // Hide exactly at breakpoint
    ._hidden-#{$breakpoint-name} {
      $keys: map-keys($grid-breakpoints);
      $index: index($keys, $breakpoint-name);
      $mq: ();

      @if $index == 1 {
        $mq: append($mq, $until);
      }

      @else if $index == length($keys) {
        $mq: append($mq, $from);
      }

      @else {
        $mq: append($mq, $from);
        $mq: append($mq, "<#{nth($keys, $index + 1)}");
      }

      @include media($mq...) {
        display: none;
      }
    }
  }
}

._text-max {
  max-width: $base-sentence-length;
}

._float-right {
  float: right !important;
}
