$fluid-viewport: (
    map-get($breakpoints, "sm"),
    map-get($breakpoints, "lg")
);

:root {
  font-family: $base-font-family;
  font-size: 16px;
  line-height: 1.5;

  @media (min-resolution: 2dppx) {
    @include gd-font-smoothing;
  }

  @include media(">=md") {
    font-size: 18px;
    line-height: 1.56;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
  line-height: 1.2;
  word-break: break-word;

  &:first-child {
    margin-top: 0;
  }
}

h1, .h1 {
  @include gd-fluid-property(font-size, 28px, 84px);
}

h2, .h2 {
  @include gd-fluid-property(font-size, 28px, 64px);

  margin-bottom: 4space;
}

h3, .h3 {
  @include gd-fluid-property(font-size, 18px, 46px);

  margin-bottom: 3space;
}

h4, .h4 {
  @include gd-fluid-property(font-size, 18px, 36px);

  @include media("<lg") {
    margin-bottom: 3space;
  }

  @include media(">=lg") {
    margin-bottom: 2space;
  }
}

h5, .h5 {
  @include gd-fluid-property(font-size, 18px, 24px);

  @include media("<md") {
    margin-bottom: 2space;
  }

  @include media(">=md") {
    margin-bottom: 1space;
  }
}

h6, .h6 {
  @include gd-fluid-property(font-size, 16px, 22px);
}

strong, b {
  font-weight: 700;
}

%body-text-extra-small {
  @include media("<md") {
    font-size: 12px;
  }

  @include media(">=md") {
    font-size: 14px;
  }
}

%body-text-small {
  @include media("<md") {
    font-size: 14px;
  }

  @include media(">=md") {
    font-size: 16px;
  }
}

%body-text-large {
  line-height: 1.5;

  @include media("<md") {
    font-size: 18px;
  }

  @include media(">=md") {
    font-size: 20px;
  }
}

%body-text-extra-large {
  line-height: 1.5;

  @include media("<md") {
    font-size: 16px;
  }

  @include media(">=md", "<lg") {
    font-size: 20px;
  }

  @include media(">=lg") {
    font-size: 28px;
  }
}
