.cookie-popup {
  align-items: center;
  background-color: $palette-light-blue-20;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 1rem;
  position: fixed;
  transform: translateY(5%);
  transition: transform $base-transition-duration, opacity $base-transition-duration;
  width: 100%;
  z-index: $z-overlay;

  &:not(.-show) {
    pointer-events: none;
    user-select: none;
  }

  &.-show {
    opacity: 1;
    transform: translateY(0);
  }

  @include media("<md") {
    flex-direction: column;
  }

  > .text {
    color: $palette-black;
  }

  > .action {
    @include media("<md") {
      margin-left: auto;
    }

    @include media(">=md") {
      margin-left: 1rem;
    }
  }
}
