.form-checkbox {
  $checkbox-size: 23px;
  $checkbox-margin: 1space;

  display: block;

  > .input {
    position: absolute;
    visibility: hidden;

    &:checked {
      + .label {
        &::after {
          transform: scale(0.8);
          opacity: 1;
        }

        + .message {
          max-height: 0;
          opacity: 0;
        }
      }
    }

    &:not(:checked) {
      + .label::before {
        opacity: 0.3;
      }
    }

    + .label {
      align-items: flex-start;
      color: $color-text-default;
      cursor: pointer;
      display: flex;
      font-weight: 700;
      position: relative;

      &:not(:empty)::before {
        margin-right: $checkbox-margin;
      }

      &::before {
        background-color: $palette-white;
        border-color: currentColor;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        content: "";
        min-height: $checkbox-size;
        min-width: $checkbox-size;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Cpath fill='%23000000' d='M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        height: $checkbox-size;
        width: $checkbox-size;

        transform: scale(0.2);
        transition-duration: $base-transition-duration / 2;
        transition-property: opacity, transform;
        transition-timing-function: $base-transition-easing;
      }

      + .message {
        max-height: 999px;
        opacity: 0.5;
        transition-duration: $base-transition-duration;
        transition-property: opacity, max-height;
        transition-timing-function: $base-transition-easing;
        padding-left: calc(#{$checkbox-size} + #{$checkbox-margin});
      }
    }
  }

  &.-error {
    > .input:not(:checked) + .label {
      color: $color-error;

      &::before {
        color: $color-error;
        opacity: 1;
      }

      + .message {
        color: $color-error;
        opacity: 1;
      }
    }
  }
}
