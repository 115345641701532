%grid {
  display: grid;

  @include media("<md") {
    $column-count: 4;
    $column-gap: 16px;
    $outer-margin: 36px;

    grid-column-gap: 16px;
    grid-template-columns:
      [full-bleed-start] $outer-margin - $column-gap
      [grid-start] repeat($column-count, [col-start] 1fr [col-end])
      [grid-end] $outer-margin - $column-gap
      [full-bleed-end];
  }

  @include media(">=md", "<lg") {
    $column-count: 12;
    $column-gap: 13px;
    $total-gap: $column-gap * ($column-count - 1);
    $outer-margin: 36px;
    $minimum-size: map-get($breakpoints, "md") - ($outer-margin * 2);

    $column-size: minmax(
      ($minimum-size - $total-gap) / $column-count,
      ($grid-max-width - $total-gap) / $column-count,
    );

    grid-column-gap: $column-gap;
    grid-template-columns:
      [full-bleed-start] minmax($outer-margin - $column-gap, 1fr)
      [grid-start] repeat($column-count, [col-start] $column-size [col-end])
      [grid-end] minmax($outer-margin - $column-gap, 1fr)
      [full-bleed-end];
  }

  @include media(">=lg") {
    $column-count: 24;
    $column-gap: 13px;
    $column-size: (($grid-max-width - ($column-gap * ($column-count - 1))) / $column-count);
    $outer-margin: 106px;

    grid-column-gap: $column-gap;
    grid-template-columns:
      [full-bleed-start] minmax($outer-margin - $column-gap, 1fr)
      [grid-start] repeat($column-count, [col-start] $column-size [col-end])
      [grid-end] minmax($outer-margin - $column-gap, 1fr)
      [full-bleed-end];
  }

  > * {
    grid-column: grid;
  }
}

@mixin woop {
    display: grid;
  
    @include media("<md") {
      $column-count: 4;
      $column-gap: 16px;
      $outer-margin: 36px;
  
      grid-column-gap: 16px;
      grid-template-columns:
        [full-bleed-start] $outer-margin - $column-gap
        [grid-start] repeat($column-count, [col-start] 1fr [col-end])
        [grid-end] $outer-margin - $column-gap
        [full-bleed-end];
    }
  
    @include media(">=md", "<lg") {
      $column-count: 12;
      $column-gap: 13px;
      $total-gap: $column-gap * ($column-count - 1);
      $outer-margin: 36px;
      $minimum-size: map-get($breakpoints, "md") - ($outer-margin * 2);
  
      $column-size: minmax(
        ($minimum-size - $total-gap) / $column-count,
        ($grid-max-width - $total-gap) / $column-count,
      );
  
      grid-column-gap: $column-gap;
      grid-template-columns:
        [full-bleed-start] minmax($outer-margin - $column-gap, 1fr)
        [grid-start] repeat($column-count, [col-start] $column-size [col-end])
        [grid-end] minmax($outer-margin - $column-gap, 1fr)
        [full-bleed-end];
    }
  
    @include media(">=lg") {
      $column-count: 24;
      $column-gap: 13px;
      $column-size: (($grid-max-width - ($column-gap * ($column-count - 1))) / $column-count);
      $outer-margin: 106px;
  
      grid-column-gap: $column-gap;
      grid-template-columns:
        [full-bleed-start] minmax($outer-margin - $column-gap, 1fr)
        [grid-start] repeat($column-count, [col-start] $column-size [col-end])
        [grid-end] minmax($outer-margin - $column-gap, 1fr)
        [full-bleed-end];
    }
  
    > * {
      grid-column: grid;
    }
}
