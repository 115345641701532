.article-featured {

  &:not(.-inline) {
    @extend %grid;
  
    position: relative;
  
    @include media("<md") {
      padding-top: 9space;
      padding-bottom: 9space;
    }
  
    @include media(">=md") {
      padding-top: 14space;
      padding-bottom: 14space;
    }
  
    > .backgroundimage {
      grid-column: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  
    > .content {
      background-color: rgba($palette-grape, 0.96);
      color: $palette-orange;
      z-index: 1;
  
      @include media("<md") {
        padding: 3space;
      }
  
      @include media(">=md", "<lg") {
        padding: 7space;
        grid-column: grid-start / span 18;
      }
  
      @include media(">=lg") {
        padding: 10.5space 14space;
        grid-column: grid-start / span 16;
      }
  
      //@include media(">=xl") {
      //  padding: 10.5space 14space;
      //  grid-column: grid-start / span 12;
      //}
  
      > .title {
        @extend .h4;
  
        @include media("<md") {
          margin: 0 0 3space;
        }
  
        @include media(">=md") {
          margin: 0 0 3.5space;
        }
      }
  
      > .lead {
        @include media("<md") {
          margin-bottom: 3.75space;
          padding-left: 6space;
        }
  
        @include media(">=md") {
          margin-bottom: 4.375space;
          padding-left: 7space;
        }
      }
  
      > .actions {
        @include media("<md") {
          padding-left: 6space;
        }
  
        @include media(">=md") {
          padding-left: 7space;
        }
      }
    }
  }

  &.-inline {

    position: relative;
    
    @include media(">lg") {
      padding: 6space;
    }

    @include media("<=md") {
      padding: 2space;
    }

    > .backgroundimage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
    }
    > .content {

      position: relative;

      background-color: rgba($palette-grape, 0.96);
      color: $palette-orange;
      z-index: 1;

      
  
      padding: 3space;
      
  
      > .title {
        @extend .h4;
        margin: 0 0 3space;
      }
  
      > .lead {
        margin-bottom: 3.75space;
        padding-left: 6space;
      }
  
      > .actions {
        padding-left: 6space;
      }
    }
  }
}
