@font-face {
  font-family: Meta;
  font-weight: 400;
  src: url("~@/fonts/MetaWeb-Normal.woff") format("woff");
}

@font-face {
  font-family: Meta;
  font-weight: 700;
  src: url("~@/fonts/MetaWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: Meta;
  font-weight: 300;
  src: url("~@/fonts/MetaWeb-Light.woff") format("woff");
}

@font-face {
  font-family: Meta;
  font-weight: 300;
  font-style: italic;
  src: url("~@/fonts/MetaWeb-LightIta.woff") format("woff");
}
