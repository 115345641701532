.bar-chart {
  margin: ($vertical-spacing * 6) 0;
  max-width: 930px;
  position: relative;

  > .fake + .chart {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .legend {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    > .item {
      align-items: center;
      display: flex;
      padding: $vertical-spacing 0;

      &:not(:first-child) {
        margin-right: 2rem;
      }

      > .color {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
    }
  }

  > .source {

  }

  @include media(">md") {
    > .legend {
      justify-content: center;
    }
  }
}
