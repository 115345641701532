.read-more {
  font-weight: 500;

  &::after {
    display: inline-block;
    content: "";
    width: 8px;
    height: 14px;
    mask-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='nonzero' d='M1.05 13.3L0 12.236 5.586 6.65 0 1.064 1.05 0 7.7 6.65z' fill='%23151F2C'/%3E%3C/svg%3E");
    background-color: currentColor;

    @include media("<md") {
      margin-left: 0.75space;
    }

    @include media(">=md") {
      margin-left: 0.875space;
    }
  }
}
