.testimonial-box {
  @extend %grid;

  background-color: $palette-tan;

  > .box {
    @include fade-up-animation;

    position: relative;
    grid-column: grid;
    background-color: $palette-white;
    color: $color-text-default;
    z-index: 1;

    > .quote {
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    > .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      overflow: hidden;
    }

    > .image {
      > .caption {
        > .title {
          font-weight: bold;
        }
      }
    }
  }

  // On show animation
  &.-in-viewport {
    > .box > .background::before {
      @include pseudo();

      top: 0;
      right: 0;
      height: 100%;
      background-image: $gradient-gray-light;
      transform-origin: top right;
      animation: skew-and-fade-in $base-transition-duration-long $base-transition-easing forwards;
    }

    > .box::after {
      @include visual-clip(true);
    }
  }

  @include media("<md") {
    padding: 5.125space 0 7.75space;

    &.-in-viewport > .box > .background::before {
      width: 100%;
    }

    > .box {
      > .image {
        > .caption {
          padding: 2.75space;
        }
      }

      > .quote {
        font-size: 18px;
        line-height: 1.56;
        padding: 4space 2.75space 8.375space;
      }
    }
  }

  @include media(">=md", "<lg") {
    padding: 5.25space 0 9.625space;

    &.-in-viewport > .box > .background::before {
      width: 70%;
    }

    > .box {
      padding: 10space 13space 15space;

      > .image {
        max-width: 74.34%;

        > .caption {
          padding: 2.75space;
        }
      }

      > .quote {
        font-size: 26px;
        line-height: 1.5;
        padding: 4.75space 0 0;
      }
    }
  }

  @include media(">=lg") {
    padding: 9.125space 0 10.375space;

    &.-in-viewport > .box > .background::before {
      width: 40%;
    }

    > .box {
      align-items: center;
      display: flex;
      padding: 9space 12space;

      > .image {
        min-width: 342px;

        > .caption {
          padding: 2space;
        }
      }

      > .quote {
        font-size: 32px;
        letter-spacing: 0.23px;
        line-height: 1.5;
        margin-left: 12space;
      }
    }
  }
}
