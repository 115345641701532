.faq-section {
  display: grid;
  gap: 4space;

  @include media("<lg") {
    > .title {
      margin-bottom: 12px;
    }
  }

  @include media(">=md") {
    gap: 40px;
  }
}
