.strategy-finder {
  @extend %grid;

  > .title {
    @include media("<md") {
      margin-top: 5space;
    }

    @include media(">=md", "<lg") {
      margin-top: 6space;
    }

    @include media(">=lg") {
      margin-top: 9space;
    }
  }

  > .title,
  > .description {
    @include media(">=md") {
      grid-column: col-start 1 / col-end -1;
    }

    @include media(">=lg") {
      grid-column: col-start 7 / col-end -7;
    }
  }

  > .comparison {
    grid-column: full-bleed;
    padding-bottom: 7.5space;

    @include media("<md") {
      margin-top: 7space;
    }

    @include media(">=md") {
      margin-top: 10space;
    }
  }
}
