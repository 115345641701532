.content-hub-filter-list {
  margin: -0.5space;
  padding: 0;
  list-style: none;

  > .item {
    display: inline-block;
  }

  > .item > .link {
    display: block;
    background-color: $palette-white;
    text-decoration: none;
    color: $palette-black;
    margin: 0.5space;

    @include media("<md") {
      font-size: 14px;
      padding: 0.5space 1space;
    }

    @include media(">=md") {
      font-size: 16px;
      padding: 0.5space 1space 0.1space;
    }
  }

  > .item:hover:not(.-active) > .link {
    background-color: darken($palette-white, 20%);
  }

  > .item.-active > .link {
    font-weight: 700;
    background-color: $palette-orange;
  }
}
