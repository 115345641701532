.toggle-button {
  background: transparent;
  border-radius: 0;
  border: 0;
  color: currentColor;
  display: inline-flex;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: auto;
  outline:0;

  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  > .label {
    color: black;
    text-transform: uppercase;
    margin: 0 1space;
  }
  > .hamburger {
    transform: translateX(15px);  
    font-size: 0;
    outline: none;
  }
}