.layout-default {
  @extend %grid;

  background-color: $palette-white;

  &.-content-hub {
    @include media("<md") {
      padding-top: 3space;
      padding-bottom: 3space;
    }

    @include media(">=md") {
      padding-top: 14space;
      padding-bottom: 10.5space;
    }

    > .section.-article-featured {
      @include media("<md") {
        margin: 3space 0;
      }

      @include media(">=md") {
        margin: 10.5space 0;
      }
    }
  }

  > .section {
    grid-column: grid;

    @include media("<md") {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include media(">=md", "<lg") {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @include media(">=lg") {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    // First child compensation
    &:first-child {
      @include media("<md") {
        margin-top: 10space;
      }

      @include media(">=md", "<lg") {
        margin-top: 12space;
      }

      @include media(">=lg") {
        margin-top: 18space;
      }
    }

    // Last child compensation
    &:last-child {
      @include media("<md") {
        margin-bottom: 10space;
      }

      @include media(">=md", "<lg") {
        margin-bottom: 12space;
      }

      @include media(">=lg") {
        margin-bottom: 18space;
      }
    }

    // Margin reset
    &.-page-footer {
      margin-top: 0;
      margin-bottom: 0;
    }

    // Margin compensation
    &.-about-box,
    &.-article-featured,
    &.-contact,
    &.-testimonial-box,
    &.-topic-teaser-big-a,
    &.-topic-teaser-big-b,
    &.-topic-teaser-big-c {
      + .-article-featured,
      + .-contact,
      + .-testimonial-box,
      + .-topic-teaser-big-a,
      + .-topic-teaser-big-b,
      + .-topic-teaser-big-c {
        @include media("<md") {
          margin-top: -5space;
        }

        @include media(">=md", "<lg") {
          margin-top: -6space;
        }

        @include media(">=lg") {
          margin-top: -9space;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Whitespace compensation
    &.-text,
    &.-download-list {
      + .-text,
      + .-about-box,
      + .-download-list {
        //margin-top: 0;
      }
    }

    // Full bleed sections
    &.-article-featured,
    &.-contact,
    &.-testimonial-box,
    &.-topic-teaser-big-a,
    &.-topic-teaser-big-b,
    &.-topic-teaser-big-c,
    &.-asset-groups,
    &.-strategy-finder {
      grid-column: full-bleed;
    }

    &.-strategy-finder {
      background-color: $palette-black;
      color: $palette-white;
    }

    &.-scenario-chart {
      padding-bottom: 8space;

      > .description {
        margin-bottom: 3space;
      }

      @include media("print") {
        transform: scale(0.85);
        transform-origin: top left;

        //page-break-after: always;

        ~ .-table,
        ~ .-download-list,
        ~ .-topic-teaser-small {
          display: none;
        }
      }
    }

    &.-asset-groups {
      @extend %grid;

      > .comparison {
        grid-column: full-bleed;
      }
    }

    &.-table {
      > .title {
        @extend .h4;

        margin-top: 0;
        margin-bottom: 1.75space;
      }
      @include media("<lg") {
        grid-column: full-bleed;
        > .title {
          margin-left: 4.5space;
        }
      }
    }

    // Narrow sections
    &.-text,
    &.-image,
    &.-figure-list,
    &.-bar-chart,
    &.-bar-stacked-chart,
    &.-bar-grouped-chart,
    &.-download-list,
    &.-faq-section,
    &.-infobox {
      @include media(">=md") {
        grid-column: col-start 1 / col-end -1;
      }

      @include media(">=lg") {
        grid-column: col-start 4 / col-end -4;
      }
    }

    // Semi-narrow sections
    &.-article-teaser-large-list {
      @include media(">=md") {
        grid-column: col-start 1 / col-end -1;
      }

      @include media(">=lg") {
        grid-column: col-start 3 / col-end -3;
      }
    }

    &.-text,
    &.-image {
      ul:not([class]) {
        list-style: none;
        padding-left: 0;

        > li {
          padding-left: 1em;
          position: relative;

          > * {
            margin: 0;
          }

          &::before {
            content: "\2013";
            left: 0;
            position: absolute;
            top: 0;
          }
        }
      }
    }
    &.-faq-section {
      //margin-top: 96px;
      //margin-bottom: 96px;

      &:last-of-type {
        //margin-bottom: 96px;
      }
    }
  }

  &.-footer {
    background-color: $palette-black;
    @include media("print") {
      display: none;
    }
  }
}
