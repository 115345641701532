.video-container {
    > .video {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;

        > iframe,
        embed,
        object {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
