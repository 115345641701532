.video-gallery-image-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    //&.-circle {
    //    border-radius: 100%;
    //
    //    &::before {
    //        padding-bottom: 100%;
    //    }
    //}

    > .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
