.table-wrapper {
  > .title {
    @extend .h4;
    background-color: $color-table-head-background;
    padding: 6space 0 4.5space 4space;
    margin: 0;
    @include media(">=lg") {
      padding: 6space 6space 8space 6space;
    }
  }
  > .scrollbar {
    transform: translateY(-7px);
  }
  > .asterisk {
    font-size: 16px;
    padding-left: 32px;
    margin: 4px 0 0 0;
    @include media(">=lg") {
      margin: 12px 0 0 0;
      padding-left: 48px;
    }
  }

  @include media(">=md") {
    &.-narrow {
      display: inline-block;
      background-color: $color-table-head-background;
      width: max-content;
      > .title {
        display: inline-block;
        width: max-content;
      }
      > .table {
        width: max-content;
        margin-bottom: -7px;
      }
    }
  }
}
