.debug-vertical-rhythm {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-debug;
    width: 100%;
    min-height: 100%;
    background-color: transparent;
    background-image: linear-gradient(transparent 50%, rgba(#37f, 0.25) 0%);
    background-repeat: repeat;
    background-size: 100% 1lh;
    content: "";
    pointer-events: none;
  }
}

.debug-breakpoints {
  position: relative;

  &::after {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: $z-debug;
    padding: 0.5lh 1rem;
    color: #fff;
    font-size: 0.75rem;
    font-family: monospace;
    line-height: 1lh;
    white-space: pre-wrap;
    background-color: #37f;

    @each $name, $width in $breakpoints {
      @include media(">=#{$width}") {
        content: "breakpoint: #{$name} (#{$width})";
      }
    }
  }
}

.debug-box-model {
  &,
  & *,
  & *::after,
  & *::before {
    outline: 1px solid rgba(#f33, 0.5);
  }
}
