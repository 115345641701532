.button-primary {
  @include gd-button-plain;

  color: $palette-black;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 18px; // ⚠️ Careful careful! Can affect hover clip angle!
  position: relative;
  z-index: 0;

  @include media(">=md") {
    font-size: 18px;
    padding: 10px 18px;
  }

  @include media(">=lg") {
    font-size: 20px;
    padding: 14px 18px;
  }

  &.-inverted {
    color: $palette-white;

    &::before {
      background-color: $palette-black;
    }
  }

  &.-orange {
    color: $palette-grape;

    &::before {
      background-color: $palette-orange;
    }
  }

  &.-blue {
    color: $palette-white;

    &::before {
      background-color: $palette-blue;
    }
  }

  // Using pseudo because clip-path makes it impossible to add focus state
  &::before {
    background-color: $palette-white;
    clip-path: polygon(100% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition-duration: $base-transition-duration;
    transition-property: clip-path, background-color;
    transition-timing-function: $base-transition-easing;
    width: 100%;
    z-index: -1;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      &::before {
        background-color: $palette-orange;
        clip-path: polygon(calc(100% - 0.87ch) 0, 100% 50%, 100% 100%, 0 100%, 0 0);
      }
    }

    &:focus {
      filter:
        drop-shadow(1px 0 $palette-light-blue)
        drop-shadow(0 1px $palette-light-blue)
        drop-shadow(-1px 0 $palette-light-blue)
        drop-shadow(0 -1px $palette-light-blue)
    ;
      outline: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
