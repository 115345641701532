.key-figures {
  position: relative;

  &.-dark {
    &::before {
      display: block;
      position: absolute;
      content: "";
      height: 100%;
      width: 100vw;
      top: 0;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      background-color: $palette-black;
      z-index: 0;
    }

    * {
      position: relative;
      z-index: 1;
    }

    @include media("<md") {
      padding: 4space 0 10space 0;
    }

    @include media(">=md") {
      padding: 8space 0;
    }
  }

  @include media("<md") {
    /* stylelint-disable */
    .item {
      width: 100%;
      text-align: center;

      .key-figure {
        text-align: left;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }
    }
    /* stylelint-enable */

    &::after {
      content: "flickity";
      display: none;
    }
  }

  @include media(">=md") {
    display: grid;
    grid-gap: 1.75space;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(">=lg") {
    grid-template-columns: repeat(4, 1fr);
  }
}
