.article-teaser {
  width: 100%;
  text-decoration: none;
  transition: background-color $base-transition-duration $base-transition-easing;

  > .image {
    min-width: 100%;

    &.-with-icon {
      background-color: $palette-black;
      position: relative;
      overflow: hidden;

      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 60%;
        width: 40%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
        opacity: 0.15;
        transform: skew($base-skew-angle);
        transform-origin: top right;
        z-index: 1;
      }

      // Color variants
      &.-color-grape {
        background-color: $palette-grape;
      }
      &.-color-blue {
        background-color: $palette-blue;
      }
      &.-color-grey {
        background-color: $palette-gray;
      }
      &.-color-green {
        background-color: $palette-green;
      }
    }
  }

  > .image > .icon {
    position: relative;
    padding-bottom: 66.6666%;
    background-color: $palette-white;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 35%;
    z-index: 2;
  }

  > .content {
    @include media("<md") {
      padding: 1.5space 3space 0;
    }

    @include media(">=md") {
      padding: 1.75space 3.5space 0;
    }

    > .category {
      @extend %body-text-small;

      color: $palette-gray;
    }

    > .title {
      @extend .h4;

      margin-bottom: 0;

      @include media("<md") {
        margin-top: 1.5space;
      }

      @include media(">=md") {
        margin-top: 1.75space;
      }
    }

    > .lead {
      @include media("<md") {
        margin-top: 1.5space;
      }

      @include media(">=md") {
        margin-top: 1.75space;
      }
    }

    > .actions {
      text-align: right;

      @include media("<md") {
        margin-top: 3space;
      }

      @include media(">=md") {
        margin-top: 3.5space;
      }
    }
  }

  &.-reduced {
    > .content > .title {
      @extend .h6;
    }
  }

  @media (hover: hover) {
    > .content {
      @include media("<md") {
        padding-bottom: 3space;
      }

      @include media(">=md") {
        padding-bottom: 3.5space;
      }
    }

    &:hover {
      background-color: $palette-pale-gray;

      > .content > .actions {
        color: $palette-lilac;
      }
    }
  }
}
