.quote-box {
  @include fade-up-animation;

  > .quote {
    @extend .h4;

    position: relative;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1ch;
    color: $palette-blue;
    letter-spacing: 0.23px;

    &::before {
      position: absolute;
      left: 0;
      top: -0.1ch;
      content: "«";
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: "»";
      display: inline;
      margin-left: 0;
    }
  }
}
