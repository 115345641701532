.apexcharts-tooltip {
  background-color: $palette-white !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 0 -1px 9px 0 rgba(0, 0, 0, 0.08), 0 22px 19px 0 rgba(0, 0, 0, 0.21) !important;
  font: inherit !important;
  padding: 14px !important;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}
