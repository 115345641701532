@keyframes page-hero-skew-in {
  from {
    transform: skew(0) scale(0.97);
    opacity: 0;
  }

  to {
    transform: skew($base-skew-angle) scale(1);
    opacity: 1;
  }
}

@keyframes page-hero-compensate-skew-in {
  to {
    transform: skew(-#{$base-skew-angle});
  }
}

@keyframes page-hero-content-reveal {
  from {
    transform: matrix3d(
      0.9204271321129559, -0.03050943663970484, 0, -0.00017738044557967929,
      -0.029069767441860465, 0.8676708874962461, 0, 0.00007569607386946778,
      0, 0, 1, 0,
      5, 25, 0, 1
    );
  }

  to {
    opacity: 1;
    transform: matrix3d(
      1, 0, 0, 0,
      0, 1, 0, 0,
      0, 0, 1, 0,
      0, 0, 0, 1
    );
  }
}

.page-hero {
  @extend %grid;

  background-color: $palette-black;
  color: $palette-white;
  position: relative;
  z-index: 1;
  overflow: hidden;

  // Color variants
  &.-color-grape {
    background-color: $palette-grape;
  }
  &.-color-blue {
    background-color: $palette-blue;
  }
  &.-color-grey {
    background-color: $palette-gray;
  }
  &.-color-green {
    background-color: $palette-green;
  }

  &.-small {
    @include media("<md") {
      padding-top: 12.5space;
      padding-bottom: 6.25space;
    }

    @include media(">=md", "<lg") {
      padding-top: 16.875space;
      padding-bottom: 11.25space;
    }

    @include media(">=lg") {
      padding-top: 23.75space;
      padding-bottom: 15space;
    }

    > .background {
      @include media(">=md") {
        width: 28%;
      }
      @include media("<md") {
        border-bottom-left-radius: 22px;
        height: 90%;
        width: 46%;
      }

      @include media(">=md", "<lg") {
        border-bottom-left-radius: 43px;
        height: 90%;
        width: 36%;
      }

      @include media(">=lg") {
        border-bottom-left-radius: 43px;
        height: 95%;
        width: 36%;
      }
    }

    > .label {
      @extend %body-text-extra-small;
      @extend strong;
    }

    > .title {
      @extend .h1;

      @include media("<md") {
        margin-bottom: 3space;
      }

      @include media(">=md", "<lg") {
        margin-bottom: 4space;
      }

      @include media(">=lg") {
        margin-bottom: 6space;
      }

      @include media(">=lg") {
        grid-column: grid;
      }
    }

    > .simpletitle {
      @extend .h3;

      margin-top: 3.5space;
      margin-bottom: 7space;
    }

    > .gobacklink {
      @extend %body-text-extra-small;

      text-decoration: none;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 19l1.5-1.52L3.02 9.5 11 1.52 9.5 0 0 9.5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      padding-left: 2space;
    }

    > .lead {
      @extend %body-text-extra-large;

      margin-top: 0;

      @include media(">=md") {
        line-height: 1.5;
        margin-bottom: 5space;
      }

      @include media(">=md", "<lg") {
        grid-column: col-start 2 / col-end -2;
        margin-bottom: 7.5space;
      }

      @include media(">=lg") {
        grid-column: col-start 4 / col-end -4;
        margin-bottom: 8.75space;
      }
    }

    > .actions {
      @include media(">=md", "<lg") {
        grid-column: col-start 2 / col-end -2;
      }

      @include media(">=lg") {
        grid-column: col-start 4 / col-end -4;
      }
    }
  }

  &.-small-bottom-margin {
    padding-bottom: 7space;
  }

  &.-big {
    @include media("<md") {
      padding-top: 12.5space;
      padding-bottom: 13.625space;
    }

    @include media(">=md", "<lg") {
      padding-top: 25space;
      padding-bottom: 19.375space;
    }

    @include media(">=lg") {
      padding-top: 25.625space;
      padding-bottom: 25.625space;
    }

    > .title {
      @include media("<md") {
        margin-bottom: 5space;
      }

      @include media(">=md", "<lg") {
        margin-bottom: 9.125space;
      }

      @include media(">=lg") {
        margin-bottom: 13.375space;
      }
    }

    > .keyfigures {
      @include media("<md") {
        margin-top: 4space;
      }

      @include media(">=md", "<lg") {
        margin-top: 4space;
      }

      @include media(">=lg") {
        margin-top: 4space;
      }
    }

    > .lead {
      @extend %body-text-extra-large;

      margin-top: 0;

      @include media(">=md") {
        line-height: 1.5;
        margin-bottom: 4space;
      }

      @include media(">=md", "<lg") {
        grid-column: col-start 2 / col-end -2;
        margin-bottom: 4space;
      }

      @include media(">=lg") {
        grid-column: col-start 4 / col-end -4;
        margin-bottom: 4space;
      }
    }

    > .background {
      @include media("<md") {
        border-bottom-left-radius: 22px;
        height: 90%;
        width: 64%;
      }
    }

    // > .background {
    //   @include media(">=md") {
    //     width: 28%;
    //   }
    //   @include media("<md") {
    //     border-bottom-left-radius: 22px;
    //     height: 90%;
    //     width: 46%;
    //   }

    //   @include media(">=md", "<lg") {
    //     border-bottom-left-radius: 43px;
    //     height: 90%;
    //     width: 46%;
    //   }

    //   @include media(">=lg") {
    //     border-bottom-left-radius: 43px;
    //     height: 95%;
    //     width: 46%;
    //   }
    // }
  }

  &.-in-viewport {
    > .background {
      animation: page-hero-skew-in $base-transition-duration-long $base-transition-easing forwards;

      > .image {
        animation: page-hero-compensate-skew-in $base-transition-duration-long $base-transition-easing forwards;
      }
    }

    > .background ~ * {
      animation: page-hero-content-reveal #{$base-transition-duration * 2 } $base-transition-easing forwards;
    }
  }

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    background-image:
      linear-gradient(to bottom, rgba(21, 31, 44, 0.15), rgba(21, 31, 44, 0.15)),
      linear-gradient(144deg, rgba($palette-black, 0.05) 39%, rgba(44, 53, 65, 0) 89%);
    z-index: -1;
    transform-origin: top right;
    overflow: hidden;
    grid-column: none;

    @include media("print") {
      display: none;
    }

    @include media("<md") {
      border-bottom-left-radius: 22px;
      height: 90%;
      width: 46%;
    }

    @include media(">=md", "<lg") {
      border-bottom-left-radius: 43px;
      height: 88.3%;
      width: 62%;
    }

    @include media(">=lg") {
      border-bottom-left-radius: 43px;
      height: 92%;
      width: 44.125%;
    }

    &.-with-image::after {
      @include pseudo();

      top: 0;
      left: -1px;
      width: calc(100% + 1px);
      opacity: 0.81;
      //background-image: linear-gradient(0deg, rgba(21, 31, 44, 0) 0%, #000000 100%);

      @include media("<md") {
        height: 200%;
      }

      @include media(">=md") {
        height: 66.666%;
      }
    }

    > .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center left;
      transform-origin: top right;
      filter: grayscale(100%);

      &.-icon {
        background-color: $palette-white;
        mask-repeat: no-repeat;
        mask-position: center center;
        mask-size: 25% auto;
      }
    }
  }

  > .background ~ * {
    opacity: 0;
    animation-delay: $base-transition-duration * 5;
    transform-origin: 50% 50% 0px;
    transform-style: preserve-3d;

    @for $i from 0 to 6 {
      &:nth-child(#{$i}) { animation-delay: $i * 0.1s; }
    }
  }

  > .title {
    margin: 0;
    hyphens: manual;
    word-break: inherit;

    @include media("<md") {
      max-width: calc(100% / 1.5);
    }

    @include media(">=md") {
      grid-column: col-start 2 / col-end -2;
      max-width: 80%;
    }
  }

  > .lead {
    @include media(">=md") {
      max-width: 80%;
    }
  }

  > .actions {
    @include media("print") {
      display: none;
    }

    @include media(">=md") {
      grid-column: col-start 2 / col-end -2;
    }
  }

  > .keyfigures {
    @include media(">=md", "<lg") {
      grid-column: col-start 2 / col-end -2;
    }
  }

  @include media("print") {
    display: none;
  }
}
