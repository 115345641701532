.page-footer {
  display: grid;
  color: $palette-white;

  @include media("<md") {
    padding-top: 3.5space;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2space 0;
    grid-template-areas:
      "address   address"
      "address2  address2"
      "links     links"
      "links2    links2"
      "lower lower"
    ;
  }

  @include media(">=md", "<lg") {
    padding: 7.25space 0 0;
    grid-gap: 1space 5space;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "links     address   address2"
      "links2    address   address2"
      ". address   address2"
      "lower     lower     lower"
    ;
  }

  @include media(">=lg") {
    padding-top: 7.75space;
    grid-gap: 1space 5space;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "links links2 . address address2"
      "lower lower  lower     lower   lower"
    ;
  }

  > .address a {
    text-decoration: none;
  }

  > .address > .city {
    @extend %body-text-large;
  }

  > .lower > .copyright {
    @extend %body-text-small;
  }

  > .links:first-of-type {
    grid-area: links;

    @include media("<md") {
      margin-top: 0.5space;
    }
  }

  > .links2 {
    grid-area: links2;

    @include media("<md") {
      margin-top: 0.5space;
    }

    @include media(">=md", "<lg") {
      margin-top: 0.5space;
    }
  }

  //> .languages {
  //  grid-area: languages;
  //
  //  @include media("<md") {
  //    margin-bottom: 4.625space;
  //  }
  //
  //  @include media(">=md", "<lg") {
  //    margin-top: 0.5space;
  //  }
  //}

  > .links,
  > .links2 {
    @include media(">=md", "<lg") {
      padding-left: 3.625space;
    }

    > .item > .link {
      text-decoration: none;

      @include media(">=md", "<lg") {
        hyphens: auto;
      }

      &.-active {
        color: $palette-orange;
        font-weight: 700;
      }
    }
  }

  > .address:first-of-type {
    grid-area: address;
  }

  > .address:nth-child(2) {
    grid-area: address2;

    @include media(">=md", "<lg") {
      padding-right: 3.625space;
    }
  }

  > .address {
    > .city {
      font-weight: 700;
    }

    > .content {
      @include media("<=md") {
        > p {
          &:first-of-type {
            margin-top: 7px;
          }
          &:nth-of-type(2) {
            margin-top: 26px;
          }
        }
      }
    }

    a {
      font-weight: normal;
      color: currentColor;
    }
  }

  > .lower {
    border-top: 3px solid rgba($palette-white, 0.3);
    display: flex;
    grid-area: lower;
    justify-content: space-between;
    margin-top: 2space;

    > .copyright,
    > .socialmedia {
      display: flex;
      align-items: center;

      @include media("<md") {
        padding-top: 1.875space;
        padding-bottom: 1.875space;
      }

      @include media(">=md", "<lg") {
        padding-top: 2.125space;
        padding-bottom: 3.125space;
      }

      @include media(">=lg") {
        padding-top: 3.875space;
        padding-bottom: 4.875space;
      }
    }

    > .socialmedia {
      > .link + .link {
        margin-left: 1.75space;
      }
    }
  }

  @include media("print") {
    > .lowe > .socialmedia,
    > .languages,
    > .links,
    > .links2 {
      display: none;
    }
  }
}
