.sticky-table {
  background-color: $color-table-background;
  width: 100%;

  @include media(">=md") {
    padding-left: 7.25space;
    padding-right: 7.25space;
  }

  @include media("<md") {
    padding-left: 2space;
    padding-right: 2space;
  }

  > .table {
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  > .caption {
    @extend %body-text-extra-small;

    border-top: 3px solid rgba($palette-lilac, 0.15);
    margin: 0;
    padding: 1.5space 0 4space;

    &::before {
      content: "* ";
    }
  }
}
