.comparison-bar {
  @extend %grid;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $palette-white;

  @include media(">=md") {
    background-color: $palette-calipso;
  }

  > .content {
    display: grid;
    grid-template-areas: "reset" "compare";
    padding: 4space;
    gap: 1space;

    > button {
      background: transparent;
      border: 0;
    }
    > .reset {
      grid-area: reset;
      font-size: 16px;
      color: $palette-gray;
      &.-active {
        color: $palette-calipso;
        cursor: pointer;
      }
    }
    > .compare {
      grid-area: compare;
      padding: 2space;
      background-color: $palette-calipso;

      display: grid;
      grid-template-columns: max-content max-content;
      gap: 2space;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      > .label.-long {
        display: none;
      }

      color: $palette-gray;
      >.icon {
        fill: $palette-gray;
      }

      &.-active {
        cursor: pointer;
        color: $palette-white;
        >.icon {
          fill: $palette-white;
        }
      }
    }

    @include media(">=md") {
      grid-template-areas: "reset compare";
      justify-content: space-between;
      padding: 0;
      grid-column: full-bleed;
      &::before {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
      }
      > .reset {
        padding-left: 4space;
        &.-active {
          color: $palette-white;
        }
      }
      > .compare {
        padding-right: 4space;
        > .label.-short {
          display: none;
        }
        > .label.-long {
          display: unset;
        }
      }
    }
    @include media(">=lg") {
      grid-template-columns: 1fr max-content;
      grid-column: grid;
      gap: 10space;
      > .reset {
        padding-left: 0;
        justify-self: end;
      }
      > .compare {
        padding-right: 8space;
        justify-self: end;
      }
    }
  }
}
