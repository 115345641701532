.risk-indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > .label {
    @extend %body-text-small;

    margin-bottom: $vertical-spacing * 1.5;
  }

  > .bar {
    background-image: linear-gradient(
        to left,
        #ff9e15,
        #ffce8a,
        #87c7d7,
        #356693
    );
    height: 9px;
    position: relative;
    width: 100%;

    > .indicator {
      background-color: $palette-black;
      height: 250%;
      left: inherit;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
    }
  }

  > .start,
  > .end {
    font-size: 14px;
    margin-top: $vertical-spacing;
  }
}
