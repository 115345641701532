.team-member {
  position: relative;

  @include media("<md") {
    //padding-right: 1.5space;
    padding-bottom: 3space;
  }

  @include media(">=md") {
    //padding-right: 1.75space;
    padding-bottom: 3.5space;
  }

  &.-full-height {
    height: 100%;
  }

  > .imagewrapper {
    position: relative;
    width: 100%;
    padding-bottom: 98%;
    background-color: $palette-black-20;
    z-index: 1;

    > .image {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  > .content {
    position: relative;
    z-index: 1;

    > .name {
      @include media("<md") {
        margin-top: 3.75space;
      }

      @include media(">=md") {
        margin-top: 4.375space;
      }
    }

    > .role {
      @extend %body-text-large;

      @include media("<md") {
        margin-bottom: 1.5space;
      }

      @include media(">=md") {
        margin-bottom: 1.75space;
      }
    }

    > .email {
      text-decoration: none;
      font-weight: 700;
      color: $palette-blue;

      @media (hover: hover) {
        &:hover {
          color: darken($palette-blue, 20%);
        }
      }
    }

    > .sociallinks {
      list-style: none;
      padding: 0;
      text-align: right;

      @include media("<md") {
        margin: 3space 0 0;
      }

      @include media(">=md") {
        margin: 3.5space 0 0;
      }

      > .item {
        display: inline-block;

        @include media("<md") {
          margin-right: 1.5space;
        }

        @include media(">=md") {
          margin-right: 1.75space;
        }
      }

      > .item > .link {
        color: $palette-blue;

        @media (hover: hover) {
          &:hover {
            color: darken($palette-blue, 20%);
          }
        }
      }

      > .item > .link > .icon {
        width: 26px;
      }
    }
  }

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &::before {
      @include pseudo();

      top: 0;
      right: 0;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(53, 102, 147, 0) 40%, rgba($palette-blue, 0.1) 99%);
      transform-origin: top right;
      animation: skew-and-fade-in $base-transition-duration-long $base-transition-easing forwards;
      width: 90%;
    }
  }
}
