.sticky-footer {
  display: flex;
  flex-direction: column;
  background-color: $palette-black;

  > .content {
    flex: 1 0 auto;
    background-color: $palette-white;
  }

  > .footer {
    flex-shrink: 0;
  }
}
