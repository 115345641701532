.article-teaser-small-list {
  @include media("<md") {
    &::after {
      content: "flickity";
      display: none;
    }

    > .flickity-viewport > .flickity-slider > .item {
      width: 100%;
    }
  }

  @include media(">=md", "<lg") {
    &::after {
      content: "flickity";
      display: none;
    }

    > .flickity-viewport > .flickity-slider > .item {
      width: 50%;

      & + .item {
        margin-left: 3.5space;
      }
    }
  }

  @include media(">=lg") {
    display: grid;
    grid-gap: 7space;
    grid-template-columns: repeat(3, 1fr);
  }
}
