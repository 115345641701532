.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-modal;
  background: rgba($palette-black, 0.35);

  > .modal {
    display: grid;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: $z-modal + 1;

    width: 670px;
    max-width: calc(100% - 10px);

    min-height: 60vh;
    max-height: calc(100% - 10px);
    overflow-y: scroll;

    transform: translate(-50%, -50%);

    background: $palette-white;
  }

  > .modal > .modalcontent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.5rem;

    @include media(">=md") {
      padding: 3rem;
    }

    @include media(">=lg") {
      padding: 3rem 3rem;
    }

    > .close {
      align-self: flex-end;
      display: inline-block;
      width: 35px;
      height: 35px;
      border: none;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' stroke='%23151F2C' fill='none' fill-rule='evenodd'%3E%3Cpath d='M1 23.627L23.627 1M1 1l22.627 22.627'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 1rem;

      -webkit-appearance: none;
      -moz-appearance: none;

      z-index: $z-modal + 2;

      // Compensate expanded clicking area
      margin-top: -5px;
      margin-right: -5px;

      @include media("<md") {
        top: 1.5space;
        right: 1.5space;
      }

      @include media(">=md") {
        top: 3.5space;
        right: 3.5space;
      }

      @media (hover: hover) {
        transition: $base-transition-duration $base-transition-easing;

        &:hover {
          transform: scale(1.075);
        }
      }
    }

    > .checkbox {
      > .label {
        align-items: flex-start;
      }
    }

    > .confirm {
      width: max-content;
      align-self: end;
      margin-top: auto !important;
    }
  }
}
