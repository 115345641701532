.faq-element {
  position: relative;

  > .answer {
    opacity: 0;
    height: 0;
    display: none;
  }
  &.-active {
    > .question {
      &::before {
        transform: rotate(45deg);
        background-color: $palette-orange;
      }
    }
    > .answer {
      opacity: 1;
      height: auto;
      display: block;
      > * {
        margin-bottom: 0;
      }
    }
  }
  > .question,
  > .answer {
    padding-left: 4space;
    > * {
      margin: 0;
      margin-top: 12px;
    }
  }
  > .question {
    cursor: pointer;
    > h5 {
      margin: 0;
    }
    &::before {
      position: absolute;
      top: 2px;
      left: 0;
      content: "";
      display: inline-block;
      justify-content: center;
      mask-image: url("~@/images/plus.svg");
      background-color: $palette-calipso;
      height: 18px;
      width: 18px;
      mask-repeat: no-repeat;
      mask-size: contain;
      transition: all 0.1s ease-in-out;
    }
  }
}
