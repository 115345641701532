.brand-logo {
  width: 100%;
  height: 100%;

  &.-negative > .flip {
    fill: #fffffe;
  }

  @include media("print") {
    > .flip {
      fill: #1e272e !important;
    }
  }
}
