$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 32px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 10px !default;
$hamburger-layer-color: currentColor !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

$hamburger-types: (spin) !default;

@import "~hamburgers/_sass/hamburgers/base";
@import "~hamburgers/_sass/hamburgers/types/spin";
