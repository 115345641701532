.key-figure {
  @include media("<md") {
    padding-left: 6space;
    padding-right: 6space;
  }

  > .figure {
    color: $palette-orange;
  }

  > .label {
    color: $palette-white;
  }

  &.-bright {
    > .figure {
      color: $palette-blue;
    }

    > .label {
      color: $color-text-default;
    }
  }

  &.-dark {
    > .figure {
      color: $palette-orange;
    }

    > .label {
      color: $palette-white;
    }
  }

  > .figure {
    @extend .h1;

    > .suffix {
      padding-left: 0.25em;

      &.-small {
        @extend .h5;
      }
    }
    // increase font-size only for mobile
    @include media("<=md") {
      font-size: 50px;
    }
  }

  > .label {
    @extend %body-text-large;

    @include media(">lg") {
      max-width: 85%;
    }
  }
}
