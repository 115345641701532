.nav-main {
  display: flex;
  flex-direction: column;

  > .category {
    > .link {
      text-decoration: none;
    }

    > .content {
      > .topics {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > .topic {
          display: flex;
          flex-direction: column;

          > .link {
            text-decoration: none;
          }
        }
      }
    }
  }

  @include media("print") {
    display: none;
  }

  @include media("<md") {
    > .category {
      > .link {
        font-size: 20px;
        line-height: 1.5;
        padding: 1.5space 0;
      }

      > .content {
        font-size: 18px;
        line-height: 1.56;
      }
    }
  }

  @include media(">=md", "<lg") {
    > .category {
      > .link {
        font-size: 28px;
        line-height: 1.25;
        padding: 3space 0;
      }

      > .content {
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }

  @include media("<lg") {
    > .category {
      > .link {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &.-current {
          color: $palette-orange;
          font-weight: bold;
        }

        > .toggle {
          @include gd-button-plain;

          align-items: center;
          font-size: 0;
          height: 1rem;
          justify-content: center;
          margin-left: 2space;
          width: 1rem;

          &::before {
            box-shadow: inset 2px 2px currentColor;
            content: "";
            height: 10px;
            transform: rotate(45deg);
            transition: transform $base-transition-duration;
            width: 10px;
          }
        }
      }

      &:not(:last-child) {
        box-shadow: inset 0 -2px 0 $palette-black-80;
      }

      &.-login {
        > .link > .icon {
          display: none;
        }
      }

      &.-open {
        > .link > .toggle::before {
          transform: rotate(225deg);
        }

        > .content {
          max-height: none;
        }
      }

      > .content {
        max-height: 0;
        overflow: hidden;
        padding-bottom: 1space;

        > .topics {
          > .topic {
            > .link {
              &.-topic {
                padding: 1.125space 2space;
              }

              &.-page {
                display: none;
              }
            }
          }
        }

        > .teaser {
          display: none;
        }
      }
    }
  }

  @include media(">=lg") {
    display: flex;
    flex-direction: row;
    position: relative;

    // Show topics on hover only if there is no focus from keyboard navigation.
    &:not(:focus-within) > .category:hover {
      > .content {
        opacity: 1;
        visibility: visible;
      }
    }

    > .category {
      &:not(:first-child) {
        margin-left: 3.5space;
      }

      &.-login {
        display: flex;
        margin-left: auto;
        padding-left: 1space;

        > .link {
          display: flex;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 21px;
          font-weight: bold;

          > .icon {
            margin-right: 1space;
          }
        }
      }

      // Allow tabbing through navigation.
      &:focus-within {
        > .content {
          opacity: 1;
          visibility: visible;
        }
      }

      > .link {
        font-size: 20px;
        line-height: 1.25;
        padding-bottom: 3.75space;
        padding-top: 6.25space;

        > .toggle {
          display: none;
        }

        &.-current {
          color: $palette-orange;
          font-weight: bold;
        }

        &.-current,
        &:hover,
        &:focus {
          box-shadow: inset 0 -2px currentColor;
        }
      }

      > .content {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 100%;
        transition-duration: $header-transition;
        transition-property: visibility, opacity;
        visibility: hidden;
        width: 100%;

        > .topics {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 7space;

          > .topic {
            padding-top: 6.625space;

            > .link {
              padding: 1space 0;
              transition: opacity $base-transition-duration;

              &:not(:hover):not(:focus):not(.-current) {
                opacity: 0.6;
              }

              &.-topic {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.75;
                margin-bottom: 2space;

                &.-current {
                  color: $palette-orange;
                }
              }
            }
          }
        }
      }
    }
  }
}
