.figure-list {
  @include media("<md") {
    &::after {
      content: "flickity";
      display: none;
    }
  }

  @include media(">=md") {
    display: grid;
    grid-gap: 7space;
    grid-template-columns: repeat(2, 1fr);
  }

  // In-viewport variant
  &[data-in-viewport] {
    > .item > .icon {
      transform: scale(0);
      transition: all $base-transition-duration cubic-bezier(0.32, 0.26, 0.44, 1.8);
    }

    &.-in-viewport {
      > .item > .icon {
        transform: scale(1);
      }

      @for $i from 1 to 4 {
        > .item:nth-child(#{$i}) > .icon { transition-delay: $i * 0.25s; }
      }
    }
  }
}
