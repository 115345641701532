.contact-box {
  @extend %grid;

  position: relative;
  z-index: 1;

  @include media("<md") {
    padding-top: 9space;
    padding-bottom: 9space;
  }

  @include media(">=md") {
    padding-top: 10.5space;
    padding-bottom: 10.5space;
  }

  // The orange clip
  &.-in-viewport::after {
    @include visual-clip;
  }

  background-color: $palette-pale-gray;
  color: $palette-blue;

  @include media("<md") {
    padding-top: 6space;
    padding-bottom: 12space;
  }

  @include media(">=lg") {
    > .title {
      grid-column: col-start 3 / col-end -13;
    }

    > .copy {
      grid-column: col-start 4 / col-end -13;
    }

    > .form {
      grid-column: col-start 14 / col-end -1;
    }
  }

  @include media(">=md", "<lg") {
    padding-top: 10.5space;
    padding-bottom: 17.5space;
  }

  @include media(">=lg") {
    padding-top: 21space;
    padding-bottom: 21space;
  }

  &.-success {
    color: $palette-green;
    background-color: rgba($palette-green, 0.06);
  }

  &.-error {
    color: $palette-red;
    background-color: rgba($palette-red, 0.04);
  }

  > .intro {
    @include fade-up-animation;
  }

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    grid-column: full-bleed;

    &::before {
      @include pseudo();

      top: 0;
      right: 0;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(53, 102, 147, 0) 40%, rgba($palette-blue, 0.09) 99%);
      transform-origin: top right;
      animation: skew-and-fade-in $base-transition-duration-long $base-transition-easing forwards;
      width: 40%;
    }
  }

  > .copy {
    margin-bottom: 4space;

    :first-child { margin-top: 0; }
    :last-child { margin-bottom: 0; }
  }

  > .form {
    max-width: 370px;
    margin: 0 auto;
  }
}
