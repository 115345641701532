@keyframes skew-in {
  from {
    transform: skew(0);
  }

  to {
    transform: skew($base-skew-angle);
  }
}
@keyframes skew-in-negative {
  from {
    transform: skew(0);
  }

  to {
    transform: skew(-#{$base-skew-angle});
  }
}

@keyframes skew-and-fade-in {
  from {
    transform: skew(0);
    opacity: 0;
  }

  to {
    transform: skew($base-skew-angle);
    opacity: 1;
  }
}

@keyframes blur-in-gray {
  from {
    filter: blur(20px) grayscale(100%);
  }

  to {
    filter: blur(0) grayscale(100%);
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
