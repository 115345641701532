.article-navigation {
  position: relative;
      
  padding: 3space;
  @include media(">lg") {
    padding: 6space;
  }

  > .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }
  > .content {

    position: relative;

    background-color: rgba($palette-grape, 0.96);
    color: $palette-orange;
    z-index: 1;

    max-width: 75%;

    padding: 3space 5space;

    @include media("<lg") {
      padding: 2space 4space;
      max-width: 100%;
    }
    

    > .category {
      color: $palette-white;
      font-weight: normal;
      font-size: 16px;
      @include media("<lg") {
        font-size: 14px;
      }
    }

    > .title {
      @extend .h4;
      margin: 1.5space 0;
      line-height: 50px;
      @include media("<lg") {
        line-height: 4space;
      }
    }

    > .actions {
      > .link {
        text-decoration: none;
        font-size: 18px;
        margin-top: 2space;
        @include media("<lg") {
          font-size: 14px;
          &::after {
            width: 8px;
            height: 8px;
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
          }
        }
      }
    }
  }
}