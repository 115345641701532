.apexcharts-xaxis-label {
  @include media("<md") {
    font-size: 12px;
  }

  @include media(">=md", "<lg") {
    font-size: 16px;
  }

  @include media(">=lg") {
    font-size: 18px;
  }

  > :nth-child(2) {
    font-size: 12px;
  }
}