.site-header {
  @extend %grid;
  position: fixed;
  z-index: $z-header;
  width: 100%;
  top: 0;
  left: 0;

  display: grid;
  grid-row: 1;
  opacity: 0;
  transform: translateY(-100%);

  $header-height: 89px;

  transition-duration: $base-transition-duration;
  transition-timing-function: $base-transition-easing;
  transition-property: transform, background-size;

  background-repeat: no-repeat;
  background-size: 100% 0%;

  @include media("print") {
    display: none;
  }

  &::before {
    content: "";
    grid-column: full-bleed;
    grid-row: 1;
  }

  &.-inverted {
    background-image: linear-gradient(to bottom, rgba($palette-white, 1), rgba($palette-white, 1));
    &::before {
      background-color: white;
      box-shadow: 0 1px 10px 0 rgba($palette-gray, 0.25);
    }
    > .burger {
      color: $palette-black;
    }
  }
  &:not(.-inverted) {
    > .burger {
      color: $palette-white;
    }
  }

  &.-visible {
    grid-template-rows: $header-height 0;
    transform: translateY(0%);
    opacity: 1;
    background-size: 100% $header-height;
  }
  &.-open {
    grid-template-rows: $header-height min-content;
    background-image: linear-gradient(to bottom, rgba($palette-white, .95), rgba($palette-white, .95));
    background-size: 100% 100%;

    &::before {
      background-color: white;
      box-shadow: inset 0 -1px 0 0 rgba($palette-gray, 0.4);
    }

    @include media(">lg") {
      &::after {
        content: "";
        position: absolute;
        background-color: rgba(#444c56, 0.9);
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;
      }
    }


    > .nav {
      opacity: 1;
      transition-delay: $base-transition-duration;
      height: 100%;
    }
    @include media("<=lg") {
      height: 100%;
      grid-template-rows: $header-height 1fr;
    }
  }
  @include media("<=lg") {
    &:not(.-open) {
      background-size: 100% $header-height;
      > .nav {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        display: none;
      }
    }
  }
  > .brand {
    grid-column: grid / span 4;
    grid-row: 1;
    margin-right: 1rem;
    align-self: center;

    > .logo {
      height: 29px;
      width: 110px;
      visibility: visible;
      opacity: 1;
    }
    > .back {
      visibility: hidden;
      opacity: 0;
      width: 0;
      background-color: transparent;
      border: 0;
    }
    @include media("<=lg") {
      &.-back {
        grid-column: full-bleed;
        > .logo {
          visibility: hidden;
          opacity: 0;
          width: 0;
        }
        > .back {
          margin-left: 1rem;
          visibility: visible;
          opacity: 1;
          width: auto;
        }
      }
    }

  }
  > .burger {
    @include media("<=lg") {
      grid-column: full-bleed;
      margin-right: 1rem;
      grid-row: 1;
      visibility: visible;
      justify-self: end;
    }
    @include media(">lg") {
      visibility: hidden;
      height: 0;
    }
  }
  > .nav {
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
    transition-property: opacity;

    @include media(">=lg") {
      grid-column: 6 / -3;
      opacity: 1;
      grid-row: 1;
    }

    @include media("<=lg") {
      grid-column: full-bleed;
      grid-row: 2;
    }
  }
}
