// Base font family.
$base-font-family: Meta, sans-serif !default;

// Base headline font family.
$base-headline-family: Meta, sans-serif !default;

// Base headline text rendering.
// @see https://developer.mozilla.org/en/docs/Web/CSS/text-rendering
$base-headline-rendering: optimizeLegibility !default;

// Base quote font family.
$base-quote-family: Menlo, Consolas, DejaVu Sans Mono, monospace !default;

// Navigation Breakpoint
$site-navigation-breakpoint: "md";

// Base maximum sentence length.
$base-sentence-length: 70ch;

// Keep transitions consistent.
$base-transition-duration: 300ms;
$base-transition-duration-long: $base-transition-duration * 5;
$base-transition-easing: cubic-bezier(0.43, 0.99, 0.67, 0.98);

// Global skewing angle
$base-skew-angle: 18deg;

// Provide access to header settings across components.
$header-breakpoint: lg;
$header-transition: $base-transition-duration * 2;