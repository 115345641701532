.asset-groups {
  @extend %grid;

  > .tabs {
    display: flex;

    &.-sticky {
      background-color: $palette-white;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    > .tab {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: auto;
      transition: opacity $base-transition-duration, color $base-transition-duration;
      user-select: none;

      &:not(.-active) {
        opacity: 0.5;
      }

      &:not(.-inverted) {
        color: $palette-blue;
      }

      &:not(:disabled) {
        &.-active,
        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      > .icon {
        fill: currentColor;
      }

      > .label {
        margin-left: 2space;
      }
    }
  }

  > .title {
    margin-top: 5space;
    margin-bottom: 0;
  }

  > .date {
    margin-top: 0;
    margin-bottom: 4space;
  }

  > .overview {
    grid-column: full-bleed;
    margin-top: 11space;
    > .title {
      font-size: 28px;
      font-weight: bold;
      padding: 6space 0 4.5space 4space;
      background-color: $color-table-head-background;

      @include media(">=lg") {
        padding: 6space 0 8space 6space;
      }

    }

    > .table {
      margin-bottom: 11space;
    }

    > .group + .title {
      margin-top: 7.5space;
    }

    @include media(">=lg") {
      grid-column: grid;
    }

    > .scrollbar {
      transform: translateY(-95px);
    }
  }

  > .comparison {
    background-color: $palette-black;
    color: $palette-white;
    grid-column: full-bleed;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: ($vertical-spacing * 8) 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-modal;

    > .close {
      font-size: 0;
      position: absolute;
      right: 2%;
      top: 2%;
      padding: 0 1space;
      line-height: 1;

      &::before {
        content: "\d7";
        color: white;
        font-size: 48px;
        font-weight: normal;
      }
    }
  }

  > .bar {
    grid-column: grid;
    @include media(">=md") {
      grid-column: full-bleed;
    }
  }
}
