.comparison-card {
  $active-bar-size: 9px;
  $card-spacing: 10px;
  $card-legend-line: 5space;

  color: $palette-black;
  max-width: 248px + ($card-spacing * 2);
  padding: $active-bar-size $card-spacing;
  position: relative;
  transition: opacity $base-transition-duration;
  width: 100%;

  @include media("print") {
    position: relative !important;
    left: 0 !important;
    opacity: 1 !important;
  }

  &::before {
    background-color: $palette-orange;
    content: "";
    height: 100%;
    left: $card-spacing;
    position: absolute;
    top: 0;
    transition: transform $base-transition-duration;
    width: calc(100% - #{$card-spacing * 2});
    transform: scaleY(0.8);
  }

  &.is-selected {
    &::before {
      transform: scaleY(1);
    }
  }

  &[style] {
    &.-invisible {
      opacity: 0.4;
    }
  }

  > .content {
    background-color: $palette-white;
    padding: 14px 20px;
    position: relative;

    > .title {
      margin-top: 0;
      margin-bottom: $vertical-spacing;
    }

    > .risk {
      margin-bottom: $vertical-spacing * 2;
    }

    > .cta {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: $vertical-spacing * 2.5;

      @include media("print") {
        display: none;
      }
    }

    > .legend {
      align-items: flex-end;
      display: flex;
      justify-content: space-around;
      margin-top: 5space;
      position: relative;

      + .chart {
        margin-top: $card-legend-line * -1;
      }

      > .item {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        z-index: 1;

        &.-hidden {
          visibility: hidden;
        }

        &::after {
          background-color: $palette-black;
          content: "";
          display: block;
          height: $card-legend-line;
          margin-top: 1space;
          transform-origin: top center;
          width: 1px;
        }

        &:first-child::after { transform: rotate(-45deg); }
        &:last-child::after { transform: rotate(45deg); }
      }
    }

    > .chart {
      margin-bottom: 4space;
    }

    > .links {
      text-align: center;
      margin-top: 5space;
      margin-bottom: 3.25space;

      + .links {
        margin-top: 0;
      }
    }
  }

  @include media(">=md") {
    $card-spacing: 34px;

    max-width: 336px + ($card-spacing * 2);
    padding-left: $card-spacing;
    padding-right: $card-spacing;

    &::before {
      left: $card-spacing;
      width: calc(100% - #{$card-spacing * 2});
    }

    > .content {
      padding: 26px 35px;

      > .title {
        margin-bottom: $vertical-spacing * 1.5;
      }
    }
  }
}
