.info-box {
  background-color: $palette-pale-gray;
  position: relative;
  z-index: 1;

  @include media("<md") {
    padding: 1.5rem;
  }

  @include media(">=md", "<lg") {
    padding: 2.5rem;
  }

  @include media(">=lg") {
    padding: 3rem;
  }

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    grid-column: full-bleed;

    &::before {
      @include pseudo();

      top: 0;
      right: 0;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(53, 102, 147, 0) 40%, rgba($palette-blue, 0.09) 99%);
      transform-origin: top right;
      animation: skew-and-fade-in $base-transition-duration-long $base-transition-easing forwards;
      animation-play-state: paused;
      width: 40%;
    }
  }

  &.-in-viewport > .background::before {
    animation-play-state: running;
    animation-delay: 0.5s;
  }

  h2 {
    @extend .h4;

    margin-top: 1rem;
  }

  h3 {
    @extend .h5;
  }
}
